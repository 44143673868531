/**
 * SignalR Invoke 消息通知执行的后台方法
 * - connection.invoke(SignalRNotifyInvoke.Register).then(() => {
 * });
 */
export const SignalRNotifyInvoke = {
  /**
   * 注册
   */
  Register: 'register',
};
