import { environment } from '@env/environment';

export const API = {
  UserResetPassword: '/api/services/app/User/ResetPassword',

  // 例子
  DemoGet: '/api/services/app/Demo/Get',
  DemoCreate: '/api1/addCostTypeTable',
  DemoUpdate: '/api1/updateCostTypeTable',
  DemoDelete: '/api1/deleteCostTypeTable',
  DemoDeleteBatch: '/api/services/app/Demo/BatchDelete',
  DemoGetAll: '/api1/countCostTypeTable',
  DemoGetDropDownList: '/api/services/app/Demo/GetDropDownList',

  // 报销类别管理
  CostTypeExport: '/api/services/app/CostType/Export',
  CostType: '/api/services/app/CostType/GetDropDownList',
  CategoryGetAll: '/api/services/app/CostType/GetAll',
  CategoryGet: '/api/services/app/CostType/Get',
  CategoryCreate: '/api/services/app/CostType/Create',
  CategoryUpdate: '/api/services/app/CostType/Update',
  CategoryDelete: '/api/services/app/CostType/Delete',

  // 报销明细
  EmployeeCostExport: '/api/services/app/EmployeeCost/Export',
  EmployeeCreate: '/api/services/app/EmployeeCost/Create',
  EmployeeDelete: '/api/services/app/EmployeeCost/Delete',
  EmployeeUpdate: '/api/services/app/EmployeeCost/Update',
  EmployeeGetAll: '/api/services/app/EmployeeCost/GetAll',
  EmployeeStatistical: '/api/services/app/EmployeeCost/Statistical',
  EmployeeGet: '/api/services/app/EmployeeCost/Get', // 根据ID查询报销
  EmployeeCostSetCharge: '/api/services/app/EmployeeCost/SetCharge',
  EmployeeCostAudit: '/api/services/app/EmployeeCost/Audit',

  EmployeeCostDetailCreate: '/api/services/app/EmployeeCostDetail/Create',
  EmployeeCostDetailUpdate: '/api/services/app/EmployeeCostDetail/Update',
  // 员工工资管理
  EmployeeWageExport: '/api/services/app/EmployeeWage/Export',
  EmployeeSalaryCreate: '/api/services/app/EmployeeWage/Create',
  EmployeeSalaryDelete: '/api/services/app/EmployeeWage/Delete',
  EmployeeSalaryUpdate: '/api/services/app/EmployeeWage/Update',
  EmployeeSalaryGetAll: '/api/services/app/EmployeeWage/GetAll',
  EmployeeSalaryGet: '/api/services/app/EmployeeWage/Get', // 根据ID查询报销
  EmployeeSalaryStatistical: '/api/services/app/EmployeeWage/Statistical', // 根据ID查询报销
  EmployeeSalaryDeleteBatch: '/api/services/app/EmployeeWage/BatchDelete',

  // 安全教育学习管理
  GetEduContent: '/api/services/app/SafetyEducation/Get',
  UpdateEduContent: '/api/services/app/SafetyEducation/Update',
  DeleateEduContent: '/api/services/app/SafetyEducation/Delete',
  DeleteBatchEduContent: '/api/services/app/SafetyEducation/BatchDelete',
  AddEduContent: '/api/services/app/SafetyEducation/Create',
  EduContentGetAll: '/api/services/app/SafetyEducation/GetAll',
  // 司机安全教育学习记录管理
  DriverEducationRecordGet: '/api/services/app/DriverEducationRecord/Get',
  DriverEducationRecordGetAll: '/api/services/app/DriverEducationRecord/GetAll',
  DriverEducationRecordCreate: '/api​/services​/app​/DriverEducationRecord​/Create',
  DriverEducationRecordUpdate: '/api/services/app/DriverEducationRecord/Update',
  DriverEducationRecordDelete: '/api/services/app/DriverEducationRecord/Delete',
  DriverEducationRecordDeleteBatch: '/api/services/app/DriverEducationRecord/BatchDelete',
  CancelInsurance: {
    Create: '/api/services/app/CancelInsurance/Create',
    GetAll: '/api/services/app/CancelInsurance/GetAll',
    Delete: '/api/services/app/CancelInsurance/Delete',
    Get: '/api/services/app/CancelInsurance/Get',
    Update: '/api/services/app/CancelInsurance/Update',
    DeleteBatch: '/api/services/app/CancelInsurance/BatchDelete',
  },
  // 合同管理模块
  TransportContract: {
    GetAllDropDownList: '/api/services/app/TransportContract/GetDropDownList',
    GetAll: '/api/services/app/TransportContract/GetAll',
    Delete: '/api/services/app/TransportContract/Delete',
    Get: '/api/services/app/TransportContract/Get',
    Create: '/api/services/app/TransportContract/Create',
    Update: '/api/services/app/TransportContract/Update',
    DeleteBatch: '/api/services/app/TransportContract/BatchDelete',
    Export: '/api/services/app/TransportContract/Export',
  },
  DriverContract: {
    Create: '/api/services/app/DriverContract/Create',
    GetAll: '/api/services/app/DriverContract/GetAll',
    Delete: '/api/services/app/DriverContract/Delete',
    Get: '/api/services/app/DriverContract/Get',
    Update: '/api/services/app/DriverContract/Update',
    DeleteBatch: '/api/services/app/DriverContract/BatchDelete',
  },
  OfficeContract: {
    Create: '/api/services/app/OfficeContract/Create',
    GetAll: '/api/services/app/OfficeContract/GetAll',
    Delete: '/api/services/app/OfficeContract/Delete',
    Get: '/api/services/app/OfficeContract/Get',
    Update: '/api/services/app/OfficeContract/Update',
    DeleteBatch: '/api/services/app/OfficeContract/BatchDelete',
    Export: '/api/services/app/OfficeContract/Export',
  },
  // 综合合同
  ContractBase: {
    GetAll: '/api/services/app/ContractBase/GetAll',
    Export: '/api/services/app/ContractBase/Export',
  },
  /** 系统设置 */

  // 常用货物设置
  // 货物管理
  GoodsCreate: '/api/services/app/Good/Create',
  GoodsDelete: '/api/services/app/Good/Delete',
  GoodsDeleteBatch: '/api/services/app/Good/BatchDelete',
  GoodsUpdate: '/api/services/app/Good/Update',
  GoodsGetAll: '/api/services/app/Good/GetAll',
  GoodsGet: '/api/services/app/Good/Get', // 使用ID查询
  GoodsGetAllDropDownList: '/api/services/app/Good/GetAllDropDownList', // 获取所有货物下拉列表
  GoodsGetDropDownList: '/api/services/app/Good/GetDropDownList', // 获取货物下拉列表

  // 货物品种管理
  GoodsTypeCreate: '/api/services/app/GoodsType/Create',
  GoodsTypeDelete: '/api/services/app/GoodsType/Delete',
  GoodsTypeDeleteBatch: '/api/services/app/GoodsType/BatchDelete',
  GoodsTypeUpdate: '/api/services/app/GoodsType/Update',
  GoodsTypeGetAll: '/api/services/app/GoodsType/GetAll',
  GoodsTypeGet: '/api/services/app/GoodsType/Get', // 使用ID查询
  GoodsTypeGetDropDownList: '/api/services/app/GoodsType/GetDropDownList', // 获取货物品种下拉列表

  // 规格管理
  GuiGeCreate: '/api/services/app/GuiGe/Create',
  GuiGeDelete: '/api/services/app/GuiGe/Delete',
  GuiGeDeleteBatch: '/api/services/app/GuiGe/BatchDelete',
  GuiGeUpdate: '/api/services/app/GuiGe/Update',
  GuiGeGetAll: '/api/services/app/GuiGe/GetAll',
  GuiGeGet: '/api/services/app/GuiGe/Get', // 使用ID查询
  GuiGeGetDropDownList: '/api/services/app/GuiGe/GetDropDownList', // 获取货物规格下拉列表

  // 规格管理
  ReimbursementTypeCreate: '/api/services/app/ReimbursementType/Create',
  ReimbursementTypeDelete: '/api/services/app/ReimbursementType/Delete',
  ReimbursementTypeDeleteBatch: '/api/services/app/ReimbursementType/BatchDelete',
  ReimbursementTypeUpdate: '/api/services/app/ReimbursementType/Update',
  ReimbursementTypeGetAll: '/api/services/app/ReimbursementType/GetAll',
  ReimbursementTypeGet: '/api/services/app/ReimbursementType/Get', // 使用ID查询
  ReimbursementTypeGetDropDownList: '/api/services/app/ReimbursementType/GetDropDownList', // 获取货物规格下拉列表

  /**收发货信息
   * 杨应红
   */
  ReceiptDeliveryInfo: {
    Create: '/api/services/app/ReceiptDeliveryInfo/Create',
    GetAll: '/api/services/app/ReceiptDeliveryInfo/GetAll',
    Delete: '/api/services/app/ReceiptDeliveryInfo/Delete',
    Get: '/api/services/app/ReceiptDeliveryInfo/Get',
    Update: '/api/services/app/ReceiptDeliveryInfo/Update',
    DeleteBatch: '/api/services/app/ReceiptDeliveryInfo/BatchDelete',
  },

  // 企业收发货信息设置
  ReceiverGet: '/api/services/app/Receiver/Get',
  ReceiverCreate: '/api/services/app/Receiver/Create',
  ReceiverUpdate: '/api/services/app/Receiver/Update',
  ReceiverDelete: '/api/services/app/Receiver/Delete',
  ReceiverDeleteBatch: '/api/services/app/Receiver/BatchDelete',
  ReceiverGetAll: '/api/services/app/Receiver/GetAll',
  ReceiverDropDownList: '/api/services/app/Receiver/GetDropDownList',

  // 企业信息设置
  TenantsGet: '/api/services/app/Tenant/Get',
  GetTenantInfo: '/api/services/app/Tenant/GetTenantInfo',
  TenantsCreate: '/api/services/app/Tenant/Create',
  TenantsUpdate: '/api/services/app/Tenant/Update',
  TenantsDelete: '/api/services/app/Tenant/Delete',
  TenantsGetAll: '/api/services/app/Tenant/GetAll',
  RefreshToken: '/api/TokenAuth/RefreshToken',

  // 登录
  Login: '/api/services/app/TokenAuth/Authenticate',
  logout: '/api/services/app/TokenAuth/LogOut',
  IsTenantAvailable: '/api/services/app/Account/IsTenantAvailable',
  Authenticate: '/api/services/app/TokenAuth/Authenticate',

  // 获取某枚举下拉数据
  GetDropDownList: '/api/services/app/Enum/GetDropDownList',

  // 获取字典下拉数据
  GetDictionaryList: '/api/services/app/Dictionary/GetDropDownList?TypeCode=',

  // 获取部门下拉列表
  GetDepartmentId: '/api/services/app/Department/GetDropDownList',

  // 获取证件类别下拉列表
  GetCertificateTypeDrowDownList: '/api/services/app/CertificateType/GetDropDownList',
  GetDriverCertificateNameDropDownList: '/api/services/app/CertificateType/GetDriverCertificateNameDropDownList',

  // 运单管理
  ConsignmentGetAll: '/api/services/app/ConsignBill/GetAll',
  ConsignmentCreate: '/api/services/app/ConsignBill/Create',
  ConsignmentUpdate: '/api/services/app/ConsignBill/Update',
  ConsignmentDelete: '/api/services/app/ConsignBill/Delete',
  ConsignmentGetId: '/api/services/app/ConsignBill/Get',
  ConsignmentDeleteBatch: '/api/services/app/ConsignBill/BatchDelete',
  ConsignmentExport: '/api/services/app/ConsignBill/Export',

  // 车辆维修
  CarMaintainGet: '/api/services/app/CarMaintain/Get',
  CarMaintainCreate: '/api/services/app/CarMaintain/Create',
  CarMaintainUpdate: '/api/services/app/CarMaintain/Update',
  CarMaintainDelete: '/api/services/app/CarMaintain/Delete',
  CarMaintainDeleteBatch: '/api/services/app/CarMaintain/BatchDelete',
  CarMaintainGetAll: '/api/services/app/CarMaintain/GetAll',

  // 车辆油耗里程
  CarFuelMileageGet: '/api/services/app/CarFuelMileage/Get',
  CarFuelMileageCreate: '/api/services/app/CarFuelMileage/Create',
  CarFuelMileageUpdate: '/api/services/app/CarFuelMileage/Update',
  CarFuelMileageDelete: '/api/services/app/CarFuelMileage/Delete',
  CarFuelMileageDeleteBatch: '/api/services/app/CarFuelMileage/BatchDelete',
  CarFuelMileageGetAll: '/api/services/app/CarFuelMileage/GetAll',

  // 车辆隐患排查
  CarDangerGet: '/api/services/app/CarDanger/Get',
  CarDangerCreate: '/api/services/app/CarDanger/Create',
  CarDangerUpdate: '/api/services/app/CarDanger/Update',
  CarDangerDelete: '/api/services/app/CarDanger/Delete',
  CarDangerDeleteBatch: '/api/services/app/CarDanger/BatchDelete',
  CarDangerGetAll: '/api/services/app/CarDanger/GetAll',

  // 车辆事故管理
  AccidentGet: '/api/services/app/CarAccident/Get',
  AccidentCreate: '/api/services/app/CarAccident/Create',
  AccidentUpdate: '/api/services/app/CarAccident/Update',
  AccidentDelete: '/api/services/app/CarAccident/Delete',
  AccidentDeleteBatch: '/api/services/app/CarAccident/BatchDelete',
  AccidentExport: '/api/services/app/CarAccident/Export',
  AccidentGetAll: '/api/services/app/CarAccident/GetAll',

  // 保险管理-java
  CarInsuranceGet: '/api/services/app/CarInsurance/Get',
  CarInsuranceCreate: '/api/services/app/CarInsurance/Create',
  CarInsuranceUpdate: '/api/services/app/CarInsurance/Update',
  CarInsuranceDelete: '/api/services/app/CarInsurance/Delete',
  CarInsuranceDeleteBatch: '/api/services/app/CarInsurance/BatchDelete',
  CarInsuranceGetAll: '/api/services/app/CarInsurance/GetAll',

  // 车辆管理费
  CarManagementFeeGet: '/api/services/app/CarManageFee/Get',
  CarManagementFeeCreate: '/api/services/app/CarManageFee/Create',
  CarManagementFeeUpdate: '/api/services/app/CarManageFee/Update',
  CarManagementFeeDelete: '/api/services/app/CarManageFee/Delete',
  CarManagementFeeDeleteBatch: '/api/services/app/CarManageFee/BatchDelete',
  CarManagementFeeGetAll: '/api/services/app/CarManageFee/GetAll',
  CarManagementFeeStatistical: '/api/services/app/CarManageFee/Statistical',
  CarManagementFeeSetChargeStatus: '/api/services/app/CarManageFee/SetChargeStatus',
  CarManagementFeeImport: '/api/services/app/CarManageFee/Import',

  // 车辆证件管理
  CarCertificateExport: '/api/services/app/CarCertificate/Export',
  CarCertificateGet: '/api/services/app/CarCertificate/Get',
  CarCertificateCreate: '/api/services/app/CarCertificate/Create',
  CarCertificateUpdate: '/api/services/app/CarCertificate/Update',
  CarCertificateDelete: '/api/services/app/CarCertificate/Delete',
  CarCertificateDeleteBatch: '/api/services/app/CarCertificate/BatchDelete',
  CarCertificateGetAll: '/api/services/app/CarCertificate/GetAll',
  CarCertificateNotHas: '/api/services/app/CarCertificate/NotHas',

  // 司机证件管理
  DriverCertificateExport: '/api/services/app/DriverCertificate/Export',
  DriverCertificateGet: '/api/services/app/DriverCertificate/Get',
  DriverCertificateCreate: '/api/services/app/DriverCertificate/Create',
  DriverCertificateUpdate: '/api/services/app/DriverCertificate/Update',
  DriverCertificateDelete: '/api/services/app/DriverCertificate/Delete',
  DriverCertificateDeleteBatch: '/api/services/app/DriverCertificate/BatchDelete',
  DriverCertificateGetAll: '/api/services/app/DriverCertificate/GetAll',
  DriverCertificateNotHas: '/api/services/app/DriverCertificate/NotHas',

  // 综合消息提醒
  MessageGet: '/api/services/app/Message/Get',
  MessageCreate: '/api/services/app/Message/Create',
  MessageUpdate: '/api/services/app/Message/Update',
  MessageDelete: '/api/services/app/Message/Delete',
  MessageDeleteBatch: '/api/services/app/Message/BatchDelete',
  MessageGetAll: '/api/services/app/Message/GetAll',

  // 驾驶员工资管理
  DriverWageExport: '/api/services/app/DriverWage/Export',
  DriverWageGet: '/api/services/app/DriverWage/Get',
  DriverWageCreate: '/api/services/app/DriverWage/Create',
  DriverWageUpdate: '/api/services/app/DriverWage/Update',
  DriverWageDelete: '/api/services/app/DriverWage/Delete',
  DriverWageGetAll: '/api/services/app/DriverWage/GetAll',
  DriverWageStatistical: '/api/services/app/DriverWage/Statistical',

  // 车辆垫款
  CarAdvanceExport: '/api/services/app/CarAdvance/Export',
  CarAdvanceGet: '/api/services/app/CarAdvance/Get',
  CarAdvanceCreate: '/api/services/app/CarAdvance/Create',
  CarAdvanceUpdate: '/api/services/app/CarAdvance/Update',
  CarAdvanceDelete: '/api/services/app/CarAdvance/Delete',
  CarAdvanceDeleteBatch: '/api/services/app/CarAdvance/BatchDelete',
  CarAdvanceGetAll: '/api/services/app/CarAdvance/GetAll',
  CarAdvanceStatistical: '/api/services/app/CarAdvance/Statistical',

  // 车辆垫款明细
  CarAdvanceBuckleGet: '/api/services/app/CarAdvanceBuckle/Get',
  CarAdvanceBuckleCreate: '/api/services/app/CarAdvanceBuckle/Create',
  CarAdvanceBuckleUpdate: '/api/services/app/CarAdvanceBuckle/Update',
  CarAdvanceBuckleDelete: '/api/services/app/CarAdvanceBuckle/Delete',
  CarAdvanceBuckleDeleteBatch: '/api/services/app/CarAdvanceBuckle/BatchDelete',
  CarAdvanceBuckleGetAll: '/api/services/app/CarAdvanceBuckle/GetAll',

  // 车辆贷款
  CarLoanExport: '/api/services/app/CarLoan/Export',
  CarLoanGet: '/api/services/app/CarLoan/Get',
  CarLoanCreate: '/api/services/app/CarLoan/Create',
  CarLoanUpdate: '/api/services/app/CarLoan/Update',
  CarLoanDelete: '/api/services/app/CarLoan/Delete',
  CarLoanDeleteBatch: '/api/services/app/CarLoan/BatchDelete',
  CarLoanGetAll: '/api/services/app/CarLoan/GetAll',
  CarLoanStatistical: '/api/services/app/CarLoan/Statistical',

  // 车辆垫款明细
  CarLoanDetailGet: '/api/services/app/CarLoanDetail/Get',
  CarLoanDetailCreate: '/api/services/app/CarLoanDetail/Create',
  CarLoanDetailUpdate: '/api/services/app/CarLoanDetail/Update',
  CarLoanDetailDelete: '/api/services/app/CarLoanDetail/Delete',
  CarLoanDetailDeleteBatch: '/api/services/app/CarLoanDetail/BatchDelete',
  CarLoanDetailGetAll: '/api/services/app/CarLoanDetail/GetAll',

  // 司机保证金
  DriverMarginExport: '/api/services/app/DriverMargin/Export',
  DriverMarginGet: '/api/services/app/DriverMargin/Get',
  DriverMarginCreate: '/api/services/app/DriverMargin/Create',
  DriverMarginUpdate: '/api/services/app/DriverMargin/Update',
  DriverMarginDelete: '/api/services/app/DriverMargin/Delete',
  DriverMarginGetAll: '/api/services/app/DriverMargin/GetAll',
  DriverMarginDeleteBatch: '/api/services/app/DriverMargin/BatchDelete',
  DriverMarginImport: '/api/services/app/DriverMargin/Import',
  DriverMarginStatistical: '/api/services/app/DriverMargin/Statistical',

  // 司机保证金明细
  DriverMarginBuckleGet: '/api/services/app/DriverMarginBuckle/Get',
  DriverMarginBuckleCreate: '/api/services/app/DriverMarginBuckle/Create',
  DriverMarginBuckleUpdate: '/api/services/app/DriverMarginBuckle/Update',
  DriverMarginBuckleDelete: '/api/services/app/DriverMarginBuckle/Delete',
  DriverMarginBuckleDeleteBatch: '/api/services/app/DriverMarginBuckle/BatchDelete',
  DriverMarginBuckleGetAll: '/api/services/app/DriverMarginBuckle/GetAll',

  // 车辆GPS费用
  CarGpsFeeGet: '/api/services/app/CarGpsFee/Get',
  CarGpsFeeCreate: '/api/services/app/CarGpsFee/Create',
  CarGpsFeeUpdate: '/api/services/app/CarGpsFee/Update',
  CarGpsFeeDelete: '/api/services/app/CarGpsFee/Delete',
  CarGpsFeeDeleteBatch: '/api/services/app/CarGpsFee/BatchDelete',
  CarGpsFeeGetAll: '/api/services/app/CarGpsFee/GetAll',
  CarGpsFeeSetChargeStatus: '/api/services/app/CarGpsFee/SetChargeStatus',
  CarGpsFeeExport: '/api/services/app/CarGpsFee/Export',
  CarGpsFeeImport: '/api/services/app/CarGpsFee/Import',
  CarGpsFeeStatistical: '/api/services/app/CarGpsFee/Statistical',

  // 车辆安全培训费
  CarSafetyFeeGet: '/api/services/app/CarSafetyFee/Get',
  CarSafetyFeeCreate: '/api/services/app/CarSafetyFee/Create',
  CarSafetyFeeUpdate: '/api/services/app/CarSafetyFee/Update',
  CarSafetyFeeDelete: '/api/services/app/CarSafetyFee/Delete',
  CarSafetyFeeDeleteBatch: '/api/services/app/CarSafetyFee/BatchDelete',
  CarSafetyFeeGetAll: '/api/services/app/CarSafetyFee/GetAll',
  CarSafetyFeeSetChargeStatus: '/api/services/app/CarSafetyFee/SetChargeStatus',
  CarSafetyFeeExport: '/api/services/app/CarSafetyFee/Export',
  CarSafetyFeeImport: '/api/services/app/CarSafetyFee/Import',
  CarSafetyFeeStatistical: '/api/services/app/CarSafetyFee/Statistical',

  // 车辆信息
  CarExport: '/api/services/app/Car/Export',
  CarImport: '/api/services/app/Car/Import',
  ImportCarDriver: '/api/services/app/Car/ImportCarDriver',
  ImportDriver: '/api/services/app/Car/ImportDriver',
  CarTableGet: '/api/services/app/Car/Get',
  CarTableCreate: '/api/services/app/Car/Create',
  CarTableUpdate: '/api/services/app/Car/Update',
  CarTableDelete: '/api/services/app/Car/Delete',
  CarTableDeleteBatch: '/api/services/app/Car/BatchDelete',
  CarTableGetAll: '/api/services/app/Car/GetAll',
  CarTableRollIn: '/api/services/app/Car/CarRollIn',
  GetCarRelatedInfo: '/api/services/app/Car/GetCarRelatedInfo',
  CarNumberChange: '/api/services/app/Car/CarNumberChange',
  CarTransfer: '/api/services/app/Car/CarTransfer',

  // 车主信息
  CarOwnerTableGet: '/api/services/app/CarOwner/Get',
  CarOwnerTableCreate: '/api/services/app/CarOwner/Create',
  CarOwnerTableUpdate: '/api/services/app/CarOwner/Update',
  CarOwnerTableDelete: '/api/services/app/CarOwner/Delete',
  CarOwnerTableDeleteBatch: '/api/services/app/CarOwner/BatchDelete',
  CarOwnerTableGetAll: '/api/services/app/CarOwner/GetAll',
  CarOwnerTableGetDropDownList: '/api/services/app/CarOwner/GetDropDownList',
  CarOwnerExport: '/api/services/app/CarOwner/Export',

  // 司机信息
  UserDriverTableGet: '/api/services/app/Driver/Get',
  UserDriverTableCreate: '/api/services/app/Driver/Create',
  UserDriverTableUpdate: '/api/services/app/Driver/Update',
  UserDriverTableDelete: '/api/services/app/Driver/Delete',
  UserDriverTableDeleteBatch: '/api/services/app/Driver/BatchDelete',
  UserDriverTableGetAll: '/api/services/app/Driver/GetAll',
  UserDriverDropDownList: '/api/services/app/Driver/GetDropDownList',
  UserDriverExport: '/api/services/app/Driver/Export',

  // 部门管理
  DepartmentGet: '/api/services/app/Department/Get',
  DepartmentCreate: '/api/services/app/Department/Create',
  DepartmentUpdate: '/api/services/app/Department/Update',
  DepartmentDelete: '/api/services/app/Department/Delete',
  DepartmentDeleteBatch: '/api/services/app/Department/BatchDelete',
  DepartmentGetAll: '/api/services/app/Department/GetAll',

  // 企业管理

  // 证件管理
  CompanyCertificateGet: '/api/services/app/CompanyCertificate/Get',
  CompanyCertificateCreate: '/api/services/app/CompanyCertificate/Create',
  CompanyCertificateUpdate: '/api/services/app/CompanyCertificate/Update',
  CompanyCertificateDelete: '/api/services/app/CompanyCertificate/Delete',
  CompanyCertificateDeleteBatch: '/api/services/app/CompanyCertificate/BatchDelete',
  CompanyCertificateGetAll: '/api/services/app/CompanyCertificate/GetAll',

  // 证件分类管理
  CertificateTypeGet: '/api/services/app/CertificateType/Get',
  CertificateTypeCreate: '/api/services/app/CertificateType/Create',
  CertificateTypeUpdate: '/api/services/app/CertificateType/Update',
  CertificateTypeDelete: '/api/services/app/CertificateType/Delete',
  CertificateTypeDeleteBatch: '/api/services/app/CertificateType/BatchDelete',
  CertificateTypeGetAll: '/api/services/app/CertificateType/GetAll',

  // 人事管理
  UserChangePassword: '/api/services/app/User/ChangePassword',
  UserDropDownList: '/api/services/app/User/GetDropDownList?UserType=1',
  HrCreate: '/api/services/app/User/Create',
  HrDelete: '/api/services/app/User/Delete',
  HrUpdate: '/api/services/app/User/Update',
  HrGetAll: '/api/services/app/User/GetAll',
  HrGet: '/api/services/app/User/Get',
  GetUserInfo: '/api/services/app/User/GetUserInfo', // 根据ID查询
  UserImport: '/api/services/app/User/Import',
  UserExport: '/api/services/app/User/Export',

  // 角色管理
  RolesGet: '/api/services/app/Role/Get',
  RolesCreate: '/api/services/app/Role/Create',
  RolesUpdate: '/api/services/app/Role/Update',
  RolesDelete: '/api/services/app/Role/Delete',
  RolesDeleteBatch: '/api/services/app/Role/BatchDelete',
  RolesGetAll: '/api/services/app/Role/GetAll',
  GetAllPermissions: '/api/services/app/Role/GetAllPermissions',
  GetRoles: '/api/services/app/Role/GetRoles',

  // 获取省市县
  GetArea: '/api/services/app/Area/GetAll',
  // 规格下拉
  GetGuigeDropDownList: '/api/services/app/GuiGe/GetDropDownList',
  // 品种下拉
  GetGoodsTypeDropDownList: '/api/services/app/GoodsType/GetDropDownList',

  // 文件上传
  File: {
    UploadForms: '/api/services/app/File/UploadForms',
    UploadFormFile: '/api/services/app/File/UploadFormFile',
    UploadBase64s: '/api/services/app/File/UploadBase64s',
    UploadBase64: '/api/services/app/File/UploadBase64',
    Attachment: '/api/services/app/File/Attachment/',
    Thumbnail: '/api/services/app/File/Thumbnail/',
    DownFile: '/api/services/app/File/DownFile/',
    DownThumbnailFile: '/api/services/app/File/Thumbnail/',
  },
  // 车辆下拉
  GetCarDropDownList: '/api/services/app/Car/GetDropDownList',
  // 司机下拉
  GetDriverDropDownList: '/api/services/app/Driver/GetDropDownList',
  // 派车单管理
  GetTransportCreates: '/api/services/app/TransportBill/Create',
  GetTransportGetAll: '/api/services/app/TransportBill/GetAll',
  GetTransportGet: '/api/services/app/TransportBill/Get',
  GetTransportDelete: '/api/services/app/TransportBill/Delete',
  GetTransportDeleteBatch: '/api/services/app/TransportBill/BatchDelete',
  GetTransportUpdate: '/api/services/app/TransportBill/Update',
  TransportBillSetOilCharge: '/api/services/app/Payment/SetOilCharge',
  TransportBillGetOilId: '/api/services/app/TransportBill/GetOilId',
  GetTransportExport: '/api/services/app/TransportBill/Export',
  BillAddNewCar: '/api/services/app/TransportBill/AddNewCar',
  BillAddNewDriver: '/api/services/app/TransportBill/AddNewDriver',

  TransportComplete: '/api/services/app/TransportBillDetailGood/Complete',
  // 管理费和GPS通讯费配置表
  ManageFeeExport: '/api/services/app/ManageFee/Export',
  ManageFeeGet: '/api/services/app/ManageFee/Get',
  ManageFeeCreate: '/api/services/app/ManageFee/Create',
  ManageFeeUpdate: '/api/services/app/ManageFee/Update',
  ManageFeeDelete: '/api/services/app/ManageFee/Delete',
  ManageFeeDeleteBatch: '/api/services/app/ManageFee/BatchDelete',
  ManageFeeGetAll: '/api/services/app/ManageFee/GetAll',
  CarManageFeeExport: '/api/services/app/CarManageFee/Export',
  // 信用服务
  GetEnterpriseInfo: environment.DataService_Url + '/location/GetEnterpriseInfo',
  GetProvinceCode: environment.DataService_Url + '/location/GetProvinceCode',
  GetLocationByCarNumber: environment.DataService_Url + '/location/GetLocationByCarNumber',
  GetPersonInfo: environment.DataService_Url + '/location/GetPersonInfo',
  CheckVehicleExist: environment.DataService_Url + '/location/CheckVehicleExist',
  GetVehicleInfo: environment.DataService_Url + '/location/GetVehicleInfo',

  // 车辆违章管理
  CarViolationGet: '/api/services/app/CarViolation/Get',
  CarViolationCreate: '/api/services/app/CarViolation/Create',
  CarViolationUpdate: '/api/services/app/CarViolation/Update',
  CarViolationDelete: '/api/services/app/CarViolation/Delete',
  CarViolationDeleteBatch: '/api/services/app/CarViolation/BatchDelete',
  CarViolationGetAll: '/api/services/app/CarViolation/GetAll',
  CarViolationImport: '/api/services/app/CarViolation/Import',
  CarViolationExport: '/api/services/app/CarViolation/Export',

  // 应付管理
  PaymentExport: '/api/services/app/Payment/Export',
  PaymentGetAll: '/api/services/app/Payment/GetAll',
  PaymentCreate: '/api/services/app/Payment/Create',
  PaymentUpdate: '/api/services/app/Payment/Update',
  PaymentGet: '/api/services/app/Payment/Get',
  PaymentStatistical: '/api/services/app/Payment/Statistical',
  PaymentUpdateSettled: '/api/services/app/Payment/UpdateSettled',

  // 应收管理
  ReceivableExport: '/api/services/app/Receivable/Export',
  ReceviableCreate: '/api/services/app/Receivable/Create',
  ReceviableUpdate: '/api/services/app/Receivable/Update',
  ReceviableGet: '/api/services/app/Receivable/Get',
  ReceviableGetAll: '/api/services/app/Receivable/GetAll',
  ReceivableStatistical: '/api/services/app/Receivable/Statistical',
  ReceviableUpdateSettled: '/api/services/app/Receivable/UpdateSettled',

  // 燃油管理
  FuelManageImport: '/api/services/app/FuelManage/Import',
  FuelManageExport: '/api/services/app/FuelManage/Export',
  FuelManageGet: '/api/services/app/FuelManage/Get',
  FuelManageGetAll: '/api/services/app/FuelManage/GetAll',
  FuelManageCreate: '/api/services/app/FuelManage/Create',
  FuelManageUpdate: '/api/services/app/FuelManage/Update',
  FuelManageDelete: '/api/services/app/FuelManage/Delete',
  FuelManageBatchDelete: '/api/services/app/FuelManage/BatchDelete',
  FuelManageSetIsWriteOff: '/api/services/app/FuelManage/SetIsWriteOff',
  FuelManageStatistical: '/api/services/app/FuelManage/Statistical',
  // 司机标签管理
  DriverTagDropDownList: '/api/services/app/DriverTag/GetDropDownList',
  DriverTagGet: '/api/services/app/DriverTag/Get',
  DriverTagGetAll: '/api/services/app/DriverTag/GetAll',
  DriverTagCreate: '/api/services/app/DriverTag/Create',
  DriverTagUpdate: '/api/services/app/DriverTag/Update',
  DriverTagDelete: '/api/services/app/DriverTag/Delete',
  DriverTagBatchDelete: '​/api​/services​/app​/DriverTag​/BatchDelete',
  // 车辆部门自动匹配
  GetDepartNameDropDownList: '/api/services/app/Car/GetDepartNameDropDownList',
  // 司机从业范围自动匹配
  GetWorkingRangeDropDownList: '/api/services/app/Driver/GetWorkingRangeDropDownList',

  // 汽车订购合同管理 购销合同
  CarOrderDropDownList: '/api/services/app/CarOrderContract/GetDropDownList',
  CarOrderCreate: '/api/services/app/CarOrderContract/Create',
  CarOrderUpdate: '/api/services/app/CarOrderContract/Update',
  CarOrderDelete: '/api/services/app/CarOrderContract/Delete',
  CarOrderGet: '/api/services/app/CarOrderContract/Get',
  CarOrderGetAll: '/api/services/app/CarOrderContract/GetAll',
  CarOrderBatchDelete: '/api/services/app/CarOrderContract/BatchDelete',
  CarOrderExport: '/api/services/app/CarOrderContract/Export',

  // 车辆分类管理
  CarCategoryDropDownList: '/api/services/app/CarCategory/GetDropDownList',

  SmsSendRecordGetAll: '/api/services/app/SmsSendRecord/GetAll',
  SmsSendRecordExport: '/api/services/app/SmsSendRecord/Export',
  // 车牌变更记录
  CarNumberChangeGet: '/api/services/app/CarNumberChange/Get',
  CarNumberChangeDelete: '/api/services/app/CarNumberChange/Delete',
  CarNumberChangeDeleteBatch: '/api/services/app/CarNumberChange/BatchDelete',
  CarNumberChangeGetAll: '/api/services/app/CarNumberChange/GetAll',

  // 车辆过户记录管理
  CarTransferUpdate: '/api/services/app/CarTransfer/Update',
  CarTransferExport: '/api/services/app/CarTransfer/Export',
  CarTransferGet: '/api/services/app/CarTransfer/Get',
  CarTransferDelete: '/api/services/app/CarTransfer/Delete',
  CarTransferDeleteBatch: '/api/services/app/CarTransfer/BatchDelete',
  CarTransferGetAll: '/api/services/app/CarTransfer/GetAll',

  // 车辆分类管理
  CarCategoryGet: '/api/services/app/CarCategory/Get',
  CarCategoryCreate: '/api/services/app/CarCategory/Create',
  CarCategoryUpdate: '/api/services/app/CarCategory/Update',
  CarCategoryDelete: '/api/services/app/CarCategory/Delete',
  CarCategoryDeleteBatch: '/api/services/app/CarCategory/BatchDelete',
  CarCategoryGetAll: '/api/services/app/CarCategory/GetAll',

  // 油卡管理
  OilCardGet: '/api/services/app/OilCard/Get',
  OilCardCreate: '/api/services/app/OilCard/Create',
  OilCardUpdate: '/api/services/app/OilCard/Update',
  OilCardDelete: '/api/services/app/OilCard/Delete',
  OilCardDeleteBatch: '/api/services/app/OilCard/BatchDelete',
  OilCardGetAll: '/api/services/app/OilCard/GetAll',
  OilCardGetDropDownList: '/api/services/app/OilCard/GetDropDownList',
  OilCardImport: '/api/services/app/OilCard/Import',

  MainCardGet: '/api/services/app/MainCard/Get',
  MainCardCreate: '/api/services/app/MainCard/Create',
  MainCardUpdate: '/api/services/app/MainCard/Update',
  MainCardDelete: '/api/services/app/MainCard/Delete',
  MainCardDeleteBatch: '/api/services/app/MainCard/BatchDelete',
  MainCardGetAll: '/api/services/app/MainCard/GetAll',
  MainCardGetDropDownList: '/api/services/app/MainCard/GetDropDownList',
  MainCardImport: '/api/services/app/MainCard/Import',
  // 司机信息
  CarBreakGet: '/api/services/app/CarBreak/Get',
  CarBreakCreate: '/api/services/app/CarBreak/Create',
  CarBreakUpdate: '/api/services/app/CarBreak/Update',
  CarBreakDelete: '/api/services/app/CarBreak/Delete',
  CarBreakDeleteBatch: '/api/services/app/CarBreak/BatchDelete',
  CarBreakGetAll: '/api/services/app/CarBreak/GetAll',

  CompanyFileTypeCreate: '/api/services/app/CompanyFileType/Create',
  CompanyFileTypeDelete: '/api/services/app/CompanyFileType/Delete',
  CompanyFileTypeDeleteBatch: '/api/services/app/CompanyFileType/BatchDelete',
  CompanyFileTypeUpdate: '/api/services/app/CompanyFileType/Update',
  CompanyFileTypeGetAll: '/api/services/app/CompanyFileType/GetAll',
  CompanyFileTypeGet: '/api/services/app/CompanyFileType/Get', // 使用ID查询
  CompanyFileTypeGetDropDownList: '/api/services/app/CompanyFileType/GetDropDownList',

  CompanyFileCreate: '/api/services/app/CompanyFile/Create',
  CompanyFileDelete: '/api/services/app/CompanyFile/Delete',
  CompanyFileDeleteBatch: '/api/services/app/CompanyFile/BatchDelete',
  CompanyFileUpdate: '/api/services/app/CompanyFile/Update',
  CompanyFileGetAll: '/api/services/app/CompanyFile/GetAll',
  CompanyFileGet: '/api/services/app/CompanyFile/Get', // 使用ID查询
  CompanyFileGetDropDownList: '/api/services/app/CompanyFile/GetDropDownList',

  OfficeGoodCreate: '/api/services/app/OfficeGood/Create',
  OfficeGoodDelete: '/api/services/app/OfficeGood/Delete',
  OfficeGoodDeleteBatch: '/api/services/app/OfficeGood/BatchDelete',
  OfficeGoodUpdate: '/api/services/app/OfficeGood/Update',
  OfficeGoodGetAll: '/api/services/app/OfficeGood/GetAll',
  OfficeGoodGet: '/api/services/app/OfficeGood/Get', // 使用ID查询
  OfficeGoodGetDropDownList: '/api/services/app/OfficeGood/GetDropDownList',

  PurchaseCreate: '/api/services/app/Purchase/Create',
  PurchaseDelete: '/api/services/app/Purchase/Delete',
  PurchaseDeleteBatch: '/api/services/app/Purchase/BatchDelete',
  PurchaseUpdate: '/api/services/app/Purchase/Update',
  PurchaseGetAll: '/api/services/app/Purchase/GetAll',
  PurchaseGet: '/api/services/app/Purchase/Get', // 使用ID查询
  PurchaseSetChargeStatus: '/api/services/app/Purchase/SetChargeStatus',

  InventoryOutStorage: '/api/services/app/Inventory/OutStorage',
  InventoryGetAll: '/api/services/app/Inventory/GetAll',
  InventoryGet: '/api/services/app/Inventory/Get', // 使用ID查询

  OutboundGetAll: '/api/services/app/Outbound/GetAll',
  OutboundGet: '/api/services/app/Outbound/Get', // 使用ID查询

  OfficeApplyCreate: '/api/services/app/OfficeApply/Create',
  OfficeApplyDelete: '/api/services/app/OfficeApply/Delete',
  OfficeApplyDeleteBatch: '/api/services/app/OfficeApply/BatchDelete',
  OfficeApplyUpdate: '/api/services/app/OfficeApply/Update',
  OfficeApplyGetAll: '/api/services/app/OfficeApply/GetAll',
  OfficeApplyGet: '/api/services/app/OfficeApply/Get', // 使用ID查询
  OfficeApplyGetGoodAll: '/api/services/app/OfficeApply/GetGoodAll',
  OfficeApplyGetDepartmentAll: '/api/services/app/OfficeApply/GetDepartmentAll',

  DownExcel: '/api/File/DownExcel/',
  // 导入模板下载
  DownTemplate: '/api/File/DownTemplate/',

  // 保险欠费管理
  InsuranceOweGet: '/api/services/app/InsuranceOwe/Get',
  InsuranceOweCreate: '/api/services/app/InsuranceOwe/Create',
  InsuranceOweUpdate: '/api/services/app/InsuranceOwe/Update',
  InsuranceOweDelete: '/api/services/app/InsuranceOwe/Delete',
  InsuranceOweDeleteBatch: '/api/services/app/InsuranceOwe/BatchDelete',
  InsuranceOweGetAll: '/api/services/app/InsuranceOwe/GetAll',
  InsuranceOweExport: '/api/services/app/InsuranceOwe/Export',
  InsuranceOweImport: '/api/services/app/InsuranceOwe/Import',
  InsuranceOweStatistical: '/api/services/app/InsuranceOwe/Statistical',

  // 保险欠费还款管理
  InsuranceOweBuckleGet: '/api/services/app/InsuranceOweBuckle/Get',
  InsuranceOweBuckleCreate: '/api/services/app/InsuranceOweBuckle/Create',
  InsuranceOweBuckleUpdate: '/api/services/app/InsuranceOweBuckle/Update',
  InsuranceOweBuckleDelete: '/api/services/app/InsuranceOweBuckle/Delete',
  InsuranceOweBuckleDeleteBatch: '/api/services/app/InsuranceOweBuckle/BatchDelete',
  InsuranceOweBuckleGetAll: '/api/services/app/InsuranceOweBuckle/GetAll',

  // 车辆管理费
  CarPunishGet: '/api/services/app/CarPunish/Get',
  CarPunishCreate: '/api/services/app/CarPunish/Create',
  CarPunishUpdate: '/api/services/app/CarPunish/Update',
  CarPunishDelete: '/api/services/app/CarPunish/Delete',
  CarPunishDeleteBatch: '/api/services/app/CarPunish/BatchDelete',
  CarPunishGetAll: '/api/services/app/CarPunish/GetAll',
  CarPunishSetChargeStatus: '/api/services/app/CarPunish/SetChargeStatus',
  CarPunishImport: '/api/services/app/CarPunish/Import',
  CarPunishExport: '/api/services/app/CarPunish/Export',
  CarPunishStatistical: '/api/services/app/CarPunish/Statistical',

  // 欠款管理
  BorrowMoneyGet: '/api/services/app/BorrowMoney/Get',
  BorrowMoneyCreate: '/api/services/app/BorrowMoney/Create',
  BorrowMoneyUpdate: '/api/services/app/BorrowMoney/Update',
  BorrowMoneyDelete: '/api/services/app/BorrowMoney/Delete',
  BorrowMoneyDeleteBatch: '/api/services/app/BorrowMoney/BatchDelete',
  BorrowMoneyGetAll: '/api/services/app/BorrowMoney/GetAll',
  BorrowMoneyExport: '/api/services/app/BorrowMoney/Export',
  BorrowMoneyImport: '/api/services/app/BorrowMoney/Import',
  BorrowMoneyStatistical: '/api/services/app/BorrowMoney/Statistical',

  // 欠款管理还款管理
  BorrowMoneyBuckleGet: '/api/services/app/BorrowMoneyBuckle/Get',
  BorrowMoneyBuckleCreate: '/api/services/app/BorrowMoneyBuckle/Create',
  BorrowMoneyBuckleUpdate: '/api/services/app/BorrowMoneyBuckle/Update',
  BorrowMoneyBuckleDelete: '/api/services/app/BorrowMoneyBuckle/Delete',
  BorrowMoneyBuckleDeleteBatch: '/api/services/app/BorrowMoneyBuckle/BatchDelete',
  BorrowMoneyBuckleGetAll: '/api/services/app/BorrowMoneyBuckle/GetAll',

  // Notification 消息通知服务
  GetUserNotifications: '/api/services/app/Notification/GetUserNotifications',
  SetAllNotificationsAsRead: '/api/services/app/Notification/SetAllNotificationsAsRead',
  SetNotificationAsRead: '/api/services/app/Notification/SetNotificationAsRead',
  GetNotificationSettings: '/api/services/app/Notification/GetNotificationSettings',
  UpdateNotificationSettings: '/api/services/app/Notification/UpdateNotificationSettings',
  DeleteNotification: '/api/services/app/Notification/DeleteNotification',
  DeleteAllUserNotifications: '/api/services/app/Notification/DeleteAllUserNotifications',
  GetNotificationNameDropDown: '/api/services/app/Notification/GetNotificationNameDropDown',

  GetFileName: '/api/services/app/FileManager/GetMessage',
};
