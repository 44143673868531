import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { API } from 'src/app/shared/api';
import { CommonService } from 'src/app/shared/service/common.service';

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, public http: _HttpClient, @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService, public commonService: CommonService, private nextReturn: HttpHandler) { }

  private get notification(): NzMessageService {
    return this.injector.get(NzMessageService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    this.notification.error(`请求错误 ${ev.status}: ${ev.url}`, errortext);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    if (!url.endsWith('.json') && !url.startsWith('https://') && !url.startsWith('http://') && !url.startsWith('./')) {
      url = this.trim(environment.SERVER_URL) + '/' + this.trim(url);
    }

    const newReq = req.clone({ url });
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        if (event instanceof HttpResponse) {
          return this.handleData(event, newReq);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err, newReq)),
    );
  }
  private trim(value: string) {
    return value.replace(/^(\s|\/)+|(\s|\/)+$/g, '');
  }
  private handleData(event: HttpResponse<any> | HttpErrorResponse, newReq: HttpRequest<any>): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    if (event.status > 0) {
      this.injector.get(_HttpClient).end();
    }
    // this.checkStatus(event);

    // 业务处理：一些通用操作
    switch (event.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        if (event instanceof HttpResponse) {
          // console.log(event);
          const body: any = event.body;
          if (body) {
            const abpBody = event.body as HttpResult;
            if (abpBody.__abp) {
              if (!abpBody.success) {
                this.notification.error(this.getErrorMessage(body));
                // 继续抛出错误中断后续所有 Pipe、subscribe 操作，因此：this.http.get('/').subscribe() 并不会触发
                return throwError({});
              } else {
                // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
                // return of(new HttpResponse({ ...event, body: body.result }));
                // 或者依然保持完整的格式
                return of(event);
              }
            }
          }
        }
        break;
      case 401: // 未登录状态码

        if (this.tokenService.get().email && this.tokenService.get().email !== 'undefined') {
          this.RefreshToken(newReq);
        } else {
          this.newLogin();
        }

        break;
      case 400:
      case 403:
      case 404:
      case 500:
        if (event instanceof HttpErrorResponse) {
          let msg = event.message;
          if (event.error) {
            const result = event.error as HttpResult;
            if (result.__abp) {
              msg = this.getErrorMessage(result);
            }
            this.notification.error(msg);
            // this.goTo(`/exception/${event.status}`);
          }
        }
        break;
      default:
        if (event instanceof HttpErrorResponse) {
          console.warn('未可知错误，可能由于后端不支持CORS或无效配置引起', event);
          this.notification.error(event.message);
        }
        break;
    }
    if (event instanceof HttpErrorResponse) {
      return throwError(event);
    } else {
      return of(event);
    }
  }

  /**
   * 获取-失败响应消息
   * @param result 响应结果
   */
  private getErrorMessage(result: HttpResult): string {
    let msg = '';
    if (!result || result.success) {
      return msg;
    }
    const error = result.error;
    // 后台验证错误消息
    // tslint:disable-next-line: prefer-conditional-expression
    if (error.validationErrors && Array.isArray(error.validationErrors)) {
      msg = error.validationErrors[0].message;
    } else {
      msg = error.message || error.details;
    }
    return msg;
  }

  RefreshToken(newReq) {
    this.http
      .post(
        `${API.RefreshToken}?_allow_anonymous=true&refreshToken=${this.tokenService.get().email}`
      )
      .subscribe((res: any) => {
        if (res.result.accessToken && res.result.accessToken.length > 100) {
          const user = {
            token: res.result.accessToken,
            name: this.tokenService.get().name,
            email: this.tokenService.get().email,
            id: this.tokenService.get().id,
            time: this.tokenService.get().time,
            tenantId: this.tokenService.get().tenantId,
          };

          const tokenExpireDate = new Date(new Date().getTime() + 1000 * res.result.expireInSeconds);
          this.commonService.deleteCookie('Abp.AuthToken', '/');
          this.commonService.setCookieValue('Abp.AuthToken', res.result.accessToken, tokenExpireDate, '/', '.wsmsjm.cn');
          // 清空路由复用信息
          // this.reuseTabService.clear();
          // 设置用户Token信息
          (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
          this.tokenService.set(user);
          this.notification.info(`重新登录成功，请重试刚才操作`);
        } else {
          this.newLogin();
        }
        // tslint:disable-next-line:no-unused-expression

        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
      });
  }

  newLogin() {
    this.notification.error(`未登录或登录已过期，请重新登录。`);
    // 清空 token 信息
    (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
    this.goTo('/passport/login');
  }

}
/**
 * http响应返回结果
 */
export class HttpResult {
  success: boolean;
  result?: object;
  error?: {
    code?: number;
    message?: string;
    details?: string;
    validationErrors?: [{ message?: string; members?: string[] }];
  };
  unAuthorizedRequest?: boolean;
  // tslint:disable-next-line: variable-name
  __abp: boolean;
  constructor() { }
}

