import { AlainAuthConfig } from '@delon/util';

/**
 * 授权配置
 */
export const AuthConfig: AlainAuthConfig = {
  ignores: [/\/login/, /assets\//, /passport\//, /\/TokenAuth\/Authenticate/],
  login_url: '/passport/login',
  allow_anonymous_key: '_allow_anonymous',
  token_invalid_redirect: true,
  token_send_key: 'Authorization',
  token_send_template: 'Bearer ${token}',
};
