import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { HubConnection, HubConnectionBuilder, IRetryPolicy, RetryContext } from '@microsoft/signalr';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable } from 'rxjs';
const signalR = require('@microsoft/signalr');
/**
 * SignalR 服务
 */
@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  /**
   * 全局配置参数
   *////signalr?enc_auth_token=
  options = {
    queryString: () => {
      return 'enc_auth_token=' + this.tokenService.get().token
    }, // 参数
    autoReconnect: true, // 是否自动重新连接
    reconnectTime: 2000, // 重新连接时间，毫秒
    maxTries: 30, // 最大重试次数
  };

  constructor(@Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService, public _zone: NgZone, public msg: NzMessageService) { }

  /**
   * 连接到服务
   */
  connect(url: string): Observable<HubConnection> {
    return new Observable((obs) => {
      // 启动连接
      this.startConnection(url)
        .then((connection: HubConnection) => {
          console.log(`已连接到信号服务器：${url}`);
          obs.next(connection);
        })
        .catch((error) => {
          console.log(url + '连接错误：' + error.message);
          obs.error(error);
        });
    });
  }

  /**
   * 使用传输回退启动连接，先使用 HttpTransportType.WebSockets 进行连接，如果失败，逐步+1进行连接
   * - 1：WebSockets
   * - 2：ServerSentEvents
   * - 3：LongPolling
   */
  private startConnection(url): Promise<HubConnection> {
    // 添加查询字符串: https://github.com/aspnet/SignalR/issues/680
    if (this.options.queryString) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + this.options.queryString();
    }
    const start = (transport) => {
      console.log('开始连接：使用 ' + signalR.HttpTransportType[transport] + ' 传输', url);
      const builder = new signalR.HubConnectionBuilder();

      // if (this.options.autoReconnect) {
      //   builder.withAutomaticReconnect({
      //     nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
      //       const tries = retryContext.previousRetryCount + 1;
      //       console.log(`正在尝试重新连接：第${tries}次`, url);
      //       if (tries < this.options.maxTries) {
      //         return this.options.reconnectTime;
      //       } else {
      //         // 停止重新连接。
      //         return null;
      //       }
      //     },
      //   });
      // }
      console.log('开始连接：start', transport);
      const connection = builder.withUrl(url, transport).build();

      return connection
        .start()
        .then(() => {
          return connection;
        })
        .catch((error) => {
          console.log('开始连接：无法使用 ' + signalR.HttpTransportType[transport] + ' 传输：' + error.message, url);

          if (transport !== signalR.HttpTransportType.LongPolling) {
            return start(transport + 1);
          }

          return Promise.reject(error);
        });
    };

    return start(signalR.HttpTransportType.WebSockets);
  }
}
