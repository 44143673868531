import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LodopService } from '@delon/abc/lodop';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient } from '@delon/theme';
import { ALAIN_I18N_TOKEN, MenuService, SettingsService, TitleService } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API } from 'src/app/shared/api';
import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { I18NService } from '../i18n/i18n.service';
import { NotifySignalRService } from 'src/app/shared/service/chat/notify-signalR.service';
import { ChatSignalRService } from 'src/app/shared/service/chat/chat-signalR.service';
import { EventHubService } from 'src/app/shared/service/chat/event-hub.service';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    private http: _HttpClient,
    private lodopSrv: LodopService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private notifySignalRSvr: NotifySignalRService,
    private charSignalRSvr: ChatSignalRService,
    private eventHubSvr: EventHubService,
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
    const options: any = { url: 'https://localhost.lodop.net:8443/CLodopfuncs.js' };
    this.lodopSrv.cog = { ...options };
  }

  load(): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve) => {
      zip(this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`), this.httpClient.get('assets/tmp/app-data9.json'), this.http.get(API.GetUserInfo, null), this.http.get(API.GetTenantInfo, { id: this.tokenService.get().tenantId }))
        .pipe(
          // 接收其他拦截器后产生的异常消息
          catchError((res) => {
            console.warn(`StartupService.load: Network request failed`, res);
            resolve(null);
            return [];
          }),
        )
        .subscribe(
          ([langData, appData, userData, tenantData]) => {
            // setting language data
            this.translate.setTranslation(this.i18n.defaultLang, langData);
            this.translate.setDefaultLang(this.i18n.defaultLang);
            localStorage.setItem('teantName', tenantData.result.name);
            // application data
            const res: any = appData;
            // 应用信息：包括站点名、描述、年份
            this.settingService.setApp(res.app);
            // 用户信息：包括姓名、头像、邮箱地址
            res.user.name = userData.result.name;
            res.user.email = userData.result.emailAddress;
            this.settingService.setUser(res.user);
            // ACL：设置权限为全量
            this.aclService.setFull(true);
            // 初始化菜单
            this.menuService.add(res.menu);
            // 设置页面标题的后缀
            this.titleService.default = '';
            this.titleService.suffix = res.app.name;

            // 摧毁所有事件
            this.eventHubSvr.destroyAll();
            // 消息通知初始化
            this.notifySignalRSvr.init();
            // 聊天初始化
            this.charSignalRSvr.init();
          },
          () => { },
          () => {
            resolve(null);
          },
        );
    });
  }
}
