import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy } from '@delon/util';

interface Permission {
  name: string;
  parentName: string;
  displayName: string;
}

export const PermissionData: Permission[] = [
  { name: 'Pages.TransportContract', parentName: '', displayName: '运输合同' },
  { name: 'Pages.TransportContract.Create', parentName: 'Pages.TransportContract', displayName: '添加' },
  { name: 'Pages.TransportContract.Update', parentName: 'Pages.TransportContract', displayName: '修改' },
  { name: 'Pages.TransportContract.Delete', parentName: 'Pages.TransportContract', displayName: '删除' },
  { name: 'Pages.TransportContract.Detail', parentName: 'Pages.TransportContract', displayName: '查看' },
  { name: 'Pages.DriverContract', parentName: '', displayName: '司机合同' },
  { name: 'Pages.DriverContract.Create', parentName: 'Pages.DriverContract', displayName: '添加' },
  { name: 'Pages.DriverContract.Update', parentName: 'Pages.DriverContract', displayName: '修改' },
  { name: 'Pages.DriverContract.Delete', parentName: 'Pages.DriverContract', displayName: '删除' },
  { name: 'Pages.DriverContract.Detail', parentName: 'Pages.DriverContract', displayName: '查看' },
  { name: 'Pages.ConsignBill', parentName: '', displayName: '货运单管理' },
  { name: 'Pages.ConsignBill.Create', parentName: 'Pages.ConsignBill', displayName: '添加' },
  { name: 'Pages.ConsignBill.Update', parentName: 'Pages.ConsignBill', displayName: '修改' },
  { name: 'Pages.ConsignBill.Delete', parentName: 'Pages.ConsignBill', displayName: '删除' },
  { name: 'Pages.ConsignBill.Detail', parentName: 'Pages.ConsignBill', displayName: '查看' },
  { name: 'Pages.TransportBill', parentName: '', displayName: '派车单管理' },
  { name: 'Pages.TransportBill.Create', parentName: 'Pages.TransportBill', displayName: '添加' },
  { name: 'Pages.TransportBill.Update', parentName: 'Pages.TransportBill', displayName: '修改' },
  { name: 'Pages.TransportBill.Delete', parentName: 'Pages.TransportBill', displayName: '删除' },
  { name: 'Pages.TransportBill.ConfirmComplete', parentName: 'Pages.TransportBill', displayName: '确认完成' },
  { name: 'Pages.TransportBill.Detail', parentName: 'Pages.TransportBill', displayName: '查看' },
  { name: 'Pages.Payment', parentName: '', displayName: '运费应付' },
  { name: 'Pages.Payment.Create', parentName: 'Pages.Payment', displayName: '添加' },
  { name: 'Pages.Payment.Update', parentName: 'Pages.Payment', displayName: '修改' },
  { name: 'Pages.Payment.Delete', parentName: 'Pages.Payment', displayName: '删除' },
  { name: 'Pages.Payment.Detail', parentName: 'Pages.Payment', displayName: '查看' },
  { name: 'Pages.Receivable666', parentName: '', displayName: '运费应收' },
  { name: 'Pages.Receivable.Create', parentName: 'Pages.Receivable666', displayName: '添加' },
  { name: 'Pages.Receivable.Update', parentName: 'Pages.Receivable666', displayName: '修改' },
  { name: 'Pages.Receivable.Delete', parentName: 'Pages.Receivable666', displayName: '删除' },
  { name: 'Pages.Receivable.Detail', parentName: 'Pages.Receivable666', displayName: '查看' },
];

function getIdx(name: string): number {
  // id = +id;
  const idx = PermissionData.findIndex((w) => w.name === name);
  if (idx === -1) {
    throw new MockStatusError(404);
  }
  return idx;
}

export const PERMISSION = {
  '/permission': () => deepCopy(PermissionData),
  'POST /permission': (req: MockRequest) => {
    const id = req.body.id || 0;
    if (id) {
      const idx = getIdx(id);
      PermissionData[idx] = { ...PermissionData[idx], ...req.body };
      return { msg: 'ok', item: PermissionData[idx] };
    }

    const item = { ...req.body, name: PermissionData[0].name };
    PermissionData.push(item);
    return { msg: 'ok', item };
  },
  'DELETE /permission/:id': (req: MockRequest) => {
    const idx = getIdx(req.params.id || 0);
    PermissionData.splice(idx, 1);
    return { msg: 'ok' };
  },
  'POST /permission/move': (req: MockRequest) => {
    const idx = getIdx(req.body.from || 0);
    PermissionData[idx].parentName = req.body.to || 0;
    return { msg: 'ok', item: PermissionData[idx] };
  },
};
