import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeIconList, NoticeItem } from '@delon/abc/notice-icon';
import { _HttpClient } from '@delon/theme';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parse from 'date-fns/parse';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzMessageService } from 'ng-zorro-antd/message';
import { API } from 'src/app/shared/api';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventHubService } from 'src/app/shared/service/chat/event-hub.service';
import { SignalRNotifyEvent } from 'src/app/shared/service/chat/signalR-notify-event';

/**
 * 菜单通知
 */
@Component({
  selector: 'layout-pro-notify',
  templateUrl: './notify.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutProWidgetNotifyComponent {
  // data: NoticeItem[] = [
  //   {
  //     title: '通知',
  //     list: [],
  //     emptyText: '你已查看所有通知',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
  //     clearText: '清空通知',
  //   },
  //   {
  //     title: '消息',
  //     list: [],
  //     emptyText: '您已读完所有消息',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg',
  //     clearText: '清空消息',
  //   },
  //   {
  //     title: '待办',
  //     list: [],
  //     emptyText: '你已完成所有待办',
  //     emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg',
  //     clearText: '清空待办',
  //   },
  // ];
  // count = 5;
  // loading = false;

  // constructor(private msg: NzMessageService, private nzI18n: NzI18nService, private cdr: ChangeDetectorRef) {}

  // updateNoticeData(notices: NoticeIconList[]): NoticeItem[] {
  //   const data = this.data.slice();
  //   data.forEach((i) => (i.list = []));

  //   notices.forEach((item) => {
  //     const newItem = { ...item };
  //     if (typeof newItem.datetime === 'string') {
  //       newItem.datetime = parse(newItem.datetime, 'yyyy-MM-dd', new Date());
  //     }
  //     if (newItem.datetime) {
  //       newItem.datetime = formatDistanceToNow(newItem.datetime as Date, { locale: this.nzI18n.getDateLocale() });
  //     }
  //     if (newItem.extra && newItem.status) {
  //       newItem.color = {
  //         todo: undefined,
  //         processing: 'blue',
  //         urgent: 'red',
  //         doing: 'gold',
  //       }[newItem.status];
  //     }
  //     data.find((w) => w.title === newItem.type).list.push(newItem);
  //   });
  //   return data;
  // }

  // loadData() {
  //   if (this.loading) {
  //     return;
  //   }
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.data = this.updateNoticeData([
  //       {
  //         id: '000000001',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
  //         title: '你收到了 14 份新周报',
  //         datetime: '2017-08-09',
  //         type: '通知',
  //       },
  //       {
  //         id: '000000002',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png',
  //         title: '你推荐的 曲妮妮 已通过第三轮面试',
  //         datetime: '2017-08-08',
  //         type: '通知',
  //       },
  //       {
  //         id: '000000003',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png',
  //         title: '这种模板可以区分多种通知类型',
  //         datetime: '2017-08-07',
  //         read: true,
  //         type: '通知',
  //       },
  //       {
  //         id: '000000004',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/GvqBnKhFgObvnSGkDsje.png',
  //         title: '左侧图标用于区分不同的类型',
  //         datetime: '2017-08-07',
  //         type: '通知',
  //       },
  //       {
  //         id: '000000005',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
  //         title: '内容不要超过两行字，超出时自动截断',
  //         datetime: '2017-08-07',
  //         type: '通知',
  //       },
  //       {
  //         id: '000000006',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //         title: '曲丽丽 评论了你',
  //         description: '描述信息描述信息描述信息',
  //         datetime: '2017-08-07',
  //         type: '消息',
  //       },
  //       {
  //         id: '000000007',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //         title: '朱偏右 回复了你',
  //         description: '这种模板用于提醒谁与你发生了互动，左侧放『谁』的头像',
  //         datetime: '2017-08-07',
  //         type: '消息',
  //       },
  //       {
  //         id: '000000008',
  //         avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //         title: '标题',
  //         description: '这种模板用于提醒谁与你发生了互动，左侧放『谁』的头像',
  //         datetime: '2017-08-07',
  //         type: '消息',
  //       },
  //       {
  //         id: '000000009',
  //         title: '任务名称',
  //         description: '任务需要在 2017-01-12 20:00 前启动',
  //         extra: '未开始',
  //         status: 'todo',
  //         type: '待办',
  //       },
  //       {
  //         id: '000000010',
  //         title: '第三方紧急代码变更',
  //         description: '冠霖提交于 2017-01-06，需在 2017-01-07 前完成代码变更任务',
  //         extra: '马上到期',
  //         status: 'urgent',
  //         type: '待办',
  //       },
  //       {
  //         id: '000000011',
  //         title: '信息安全考试',
  //         description: '指派竹尔于 2017-01-09 前完成更新并发布',
  //         extra: '已耗时 8 天',
  //         status: 'doing',
  //         type: '待办',
  //       },
  //       {
  //         id: '000000012',
  //         title: 'ABCD 版本发布',
  //         description: '冠霖提交于 2017-01-06，需在 2017-01-07 前完成代码变更任务',
  //         extra: '进行中',
  //         status: 'processing',
  //         type: '待办',
  //       },
  //     ]);

  //     this.loading = false;

  //     this.cdr.detectChanges();
  //   }, 1000);
  // }

  // clear(type: string) {
  //   this.msg.success(`清空了 ${type}`);
  // }

  // select(res: any) {
  //   this.msg.success(`点击了 ${res.title} 的 ${res.item.title}`);
  // }
  data: NoticeItem[] = [];
  // {
  //   title: '通知',
  //   list: [],
  //   emptyText: '你已查看所有通知',
  //   emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
  //   clearText: '清空通知',
  // },
  // {
  //   title: '消息',
  //   list: [],
  //   emptyText: '您已读完所有消息',
  //   emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg',
  //   clearText: '清空消息',
  // },
  // {
  //   title: '待办',
  //   list: [],
  //   emptyText: '你已完成所有待办',
  //   emptyImage: 'https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg',
  //   clearText: '清空待办',
  // },
  count = 0;
  loading = false;
  Visible = false;
  constructor(
    private msg: NzMessageService,
    private nzI18n: NzI18nService,
    private cdr: ChangeDetectorRef,
    private http: _HttpClient,
    private router: Router,
    private date: DatePipe,
    private eventHubSvr: EventHubService,
    private notificationSvr: NzNotificationService,
  ) { }

  updateNoticeData(notices: NoticeIconList[]): NoticeItem[] {
    const data = this.data.slice();
    data.forEach((i) => (i.list = []));
    notices.forEach((item) => {
      const newItem = { ...item };
      if (typeof newItem.datetime === 'string') {
        newItem.datetime = parse(newItem.datetime, 'yyyy-MM-dd HH:mm:ss', new Date());
      }
      if (newItem.datetime) {
        newItem.datetime = formatDistanceToNow(newItem.datetime as Date, { locale: this.nzI18n.getDateLocale() });
      }

      if (newItem.extra && newItem.status) {
        newItem.color = {
          todo: undefined,
          processing: 'blue',
          urgent: 'red',
          doing: 'gold',
        }[newItem.status];
      }
      data.find((w) => w.title === newItem.type) ?.list.push(newItem);
    });
    return data;
  }

  loadData() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.http.get(API.GetNotificationNameDropDown).subscribe((res) => {
      this.data = [];
      res.result.forEach((item) => {
        this.data.push({
          title: item.name,
          list: [],
          emptyText: '暂无通知',
          emptyImage: './assets/notice_no.svg',
          clearText: '跳转消息列表',
        });
      });
      this.http.get(API.GetUserNotifications, { State: 0, SkipCount: 0, MaxResultCount: 50 }).subscribe((result) => {
        const datas = [];
        this.count = result.result.unreadCount;
        console.log(result);
        result.result.items.forEach((zhi) => {
          datas.push({
            id: zhi.id,
            avatar: './assets/notice.png',
            title: zhi.notification.data.properties.Msg,// zhi.notification.data.message,
            datetime: this.date.transform(zhi.notification.creationTime, 'yyyy-MM-dd HH:mm:ss'),
            type: zhi.notification.notificationNameText,
          });
        });
        setTimeout(() => {
          this.data = this.updateNoticeData(datas);
          this.Visible = this.count > 0 ? true : false;
          this.loading = false;

          this.cdr.detectChanges();
        }, 1000);
      });
    });
  }
  loadChange() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;

      this.cdr.detectChanges();
    }, 200);
  }
  ngOnInit() {
    this.loadData();
    this.registerEventOn();
  }

  clear(type: string) {
    // this.msg.success(`跳转消息列表成功`);
    this.router.navigateByUrl('/my-sys/my-notification');
  }

  select(res: any) {
    // this.msg.success(`跳转消息列表成功`);

    this.router.navigateByUrl('/financial/employee');
    this.http.post(API.SetNotificationAsRead, { id: res.item.id }).subscribe((jieguo) => {
      for (let i = this.data[0].list.length - 1; i >= 0; i--) {
        if (this.data[0].list[i].id == res.item.id) {
          this.data[0].list.splice(i, 1);
          this.count--;
        }
      }
    });
  }
  registerEventOn() {
    this.eventHubSvr.on(SignalRNotifyEvent.Connected.Event, () => {
      console.log('消息通知signalR已连接');
    });
    this.eventHubSvr.on(SignalRNotifyEvent.Close.Event, () => {
      console.log('消息通知signalR已断开');
    });
    this.eventHubSvr.on(SignalRNotifyEvent.Connecting.Event, () => {
      console.log('消息通知signalR连接中');
    });

    this.eventHubSvr.on(SignalRNotifyEvent.Received.Event, (userNotification) => {
      console.log('消息通知：', userNotification);

      const notificationName = userNotification.notification.notificationName;
      const data = userNotification.notification.data;
      const dataType = data.type;
      let message = '';
      if (dataType === 'Abp.Notifications.LocalizableMessageNotificationData') {
        Object.keys(data.properties).forEach((item, index) => {
          message += data.properties[item];
        });
      } else if (dataType === 'Abp.Notifications.MessageNotificationData') {
        Object.keys(data.properties).forEach((item, index) => {
          message += data.properties[item];
        });
      }
      this.notificationSvr.create('info', '消息通知：', message, { nzPlacement: 'topRight', nzDuration: 10000 });
    });
  }
  // [
  //   {
  //     id: '000000001',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
  //     title: '你收到了 14 份新周报',
  //     datetime: '2017-08-09',
  //     type: '通知',
  //   },
  //   {
  //     id: '000000002',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png',
  //     title: '你推荐的 曲妮妮 已通过第三轮面试',
  //     datetime: '2017-08-08',
  //     type: '通知',
  //   },
  //   {
  //     id: '000000003',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png',
  //     title: '这种模板可以区分多种通知类型',
  //     datetime: '2017-08-07',
  //     read: true,
  //     type: '通知',
  //   },
  //   {
  //     id: '000000004',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/GvqBnKhFgObvnSGkDsje.png',
  //     title: '左侧图标用于区分不同的类型',
  //     datetime: '2017-08-07',
  //     type: '通知',
  //   },
  //   {
  //     id: '000000005',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png',
  //     title: '内容不要超过两行字，超出时自动截断',
  //     datetime: '2017-08-07',
  //     type: '通知',
  //   },
  //   {
  //     id: '000000006',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //     title: '曲丽丽 评论了你',
  //     description: '描述信息描述信息描述信息',
  //     datetime: '2017-08-07',
  //     type: '消息',
  //   },
  //   {
  //     id: '000000007',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //     title: '朱偏右 回复了你',
  //     description: '这种模板用于提醒谁与你发生了互动，左侧放『谁』的头像',
  //     datetime: '2017-08-07',
  //     type: '消息',
  //   },
  //   {
  //     id: '000000008',
  //     avatar: 'https://gw.alipayobjects.com/zos/rmsportal/fcHMVNCjPOsbUGdEduuv.jpeg',
  //     title: '标题',
  //     description: '这种模板用于提醒谁与你发生了互动，左侧放『谁』的头像',
  //     datetime: '2017-08-07',
  //     type: '消息',
  //   },
  //   {
  //     id: '000000009',
  //     title: '任务名称',
  //     description: '任务需要在 2017-01-12 20:00 前启动',
  //     extra: '未开始',
  //     status: 'todo',
  //     type: '待办',
  //   },
  //   {
  //     id: '000000010',
  //     title: '第三方紧急代码变更',
  //     description: '冠霖提交于 2017-01-06，需在 2017-01-07 前完成代码变更任务',
  //     extra: '马上到期',
  //     status: 'urgent',
  //     type: '待办',
  //   },
  //   {
  //     id: '000000011',
  //     title: '信息安全考试',
  //     description: '指派竹尔于 2017-01-09 前完成更新并发布',
  //     extra: '已耗时 8 天',
  //     status: 'doing',
  //     type: '待办',
  //   },
  //   {
  //     id: '000000012',
  //     title: 'ABCD 版本发布',
  //     description: '冠霖提交于 2017-01-06，需在 2017-01-07 前完成代码变更任务',
  //     extra: '进行中',
  //     status: 'processing',
  //     type: '待办',
  //   },
  // ]
}
