import { AlainSTConfig } from '@delon/util';

/**
 * st table表配置
 */
export const STDefaultConfig: AlainSTConfig = {
  /**
   * 起始页码，默认为：`1`
   */
  pi: 1,
  /**
   * 每页数量，当设置为 `0` 表示不分页，默认：`10`
   */
  ps: 10,
  /**
   * 是否显示边框
   */
  bordered: false,
  /**
   * table大小
   */
  size: 'middle',
  /**
   * 是否开启响应式，默认：`true`
   */
  responsive: true,
  /**
   * 是否在小屏幕下才显示顶部与底部，默认：`false`
   */
  responsiveHideHeaderFooter: false,
  /** 请求体配置 */
  req: {
    method: 'GET',
    type: 'skip',
    reName: {
      pi: 'pageIndex',
      ps: 'pageSize',
      skip: 'skipCount',
      limit: 'maxResultCount',
    },
    allInBody: true,
    lazyLoad: false,
  },
  /** 返回体配置 */
  res: {
    reName: {
      total: 'result.totalCount',
      list: 'result.items',
    },
  },
  /** 分页配置 */
  page: {
    position: 'bottom',
    placement: 'right',
    show: true,
    showSize: true,
    pageSizes: [10, 20, 30, 40, 50],
    showQuickJumper: true,
    total: '共 {{total}} 条',
    toTop: true,
    toTopOffset: 100,
  },
  /**
   * 重命名排序值，`columns` 的重命名高于属性
   */
  sortReName: {
    ascend: 'asc',
    descend: 'desc',
  },
  /**
   * 单排序规则
   * - 若不指定，则返回：`columnName=ascend|descend`
   * - 若指定，则返回：`sort=columnName.(ascend|descend)`
   */
  singleSort: {
    key: 'sorting',
    nameSeparator: ' ',
  },
  /**
   * 是否多排序，当 `sort` 多个相同值时自动合并，建议后端支持时使用
   */
  multiSort: {
    key: 'sorting',
    separator: ',',
    nameSeparator: ' ',
    keepEmptyKey: false,
  },
  /**
   * 按钮模态框配置
   */
  modal: {
    paramsName: 'record',
    size: 'lg',
  },
  /**
   * 按钮抽屉配置
   */
  drawer: {
    paramsName: 'record',
    size: 'md',
    footer: true,
    footerHeight: 55,
  },
  /**
   * 气泡参数
   */
  pop: {
    title: '确认删除吗？',
    trigger: 'click',
    placement: 'top',
    cancelText: '取消',
    okText: '确定',
    okType: 'default',
    icon: null,
  },
  /**
   * 行单击多少时长之类为双击（单位：毫秒），默认：`200`
   */
  rowClickTime: 200,
  /**
   * 过滤按钮确认文本，默认：`确认`
   */
  filterConfirmText: '搜索',
  /**
   * 过滤按钮重置文本，默认：`重置`
   */
  filterClearText: '重置',
  /**
   * 按钮图标
   */
  // btnIcon?: STIcon;
  /**
   * 行号索引，默认：`1`
   * - 计算规则为：`index + noIndex`
   */
  noIndex: 1,
  /**
   * 表格行的类名
   */
  // rowClassName?: STRowClassName;
  /**
   * 通过点击行来展开子行
   */
  // expandRowByClick?: boolean;
  /**
   * 手风琴模式
   */
  // expandAccordion?: boolean;
  /**
   * 指定 `width` 模式
   */
  // widthMode?: STWidthMode;
  // virtualItemSize?: number | undefined;
  // virtualMaxBufferPx?: number | undefined;
  // virtualMinBufferPx?: number | undefined;
  /**
   * Conditional expression rendering behavior, can be set to `hide` (default) or `disabled`
   */
  // iifBehavior?: IifBehaviorType;
};
