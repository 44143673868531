import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import Viewer from 'viewerjs';
import { API } from '../../api';
import { CommonService } from '../../service/common.service';
@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styles: [
    ` :host ::ng-deep .avatar-uploader > .ant-upload {
            width: 128px;
            height: 128px;
          }
        `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit {
  constructor(private http: _HttpClient, private msg: NzMessageService, private commonService: CommonService, public renderer2: Renderer2) { }
  @Input() fileList: any;
  // @Input() showButton = true;
  @Output() private outer = new EventEmitter<string>();
  @ViewChild('img') img: ElementRef;
  fileUrl = API.File.UploadFormFile;
  modelStyle = { width: '100%', height: '100%' };
  backFormData = new FormData();
  file: any;
  fileSrcList: NzUploadFile[] = [
  ];
  viewer: any;
  previewImage: string | undefined = '';
  previewVisible = false;
  loading = false;
  ngOnInit(): void {
    // console.log(this.fileList);
    // this.fileList.forEach((fl) => {
    //   fl.files.forEach((item) => {
    //     this.commonService.previewFile(item.fileId).then((liaje) => {
    //       this.commonService.blobToBase64(liaje).then((res: any) => {
    //         item.thumbUrl = res.result;
    //         console.log(item);
    //       });
    //     });
    //   });
    // });
    this.previewImage = 'http://www.9t56.com/tms.jpg';
  }
  beforeUpload = (file: NzUploadFile): boolean => {
    this.file = null;
    this.file = file;
    console.log(this.file);
    // this.fileList = this.fileList.concat(file);
    return true;
  }

  handleChange(e: NzUploadChangeParam, item, index): void {
    const _this = this;
    console.log(e);
    if (e.type === 'success') {
      console.log(e);
      item.files = e.fileList;
      this.backValue();
    } else if (e.type === 'removed') {
      item.files = e.fileList;
      this.backValue();
    }
    // if (e.type === 'start') {
    //   if (item.isMultiple) {
    //     item.files.push({ uploadState: e.type, fileSrc: e.file.thumbUrl, fileName: e.file.name, fileId: '' });
    //     item.files.splice(item.files.length - 1, 1);
    //   } else {
    //     item.files = [{ uploadState: e.type, fileSrc: e.file.thumbUrl, fileName: e.file.name, fileId: '' }];
    //   }
    //   const formData = new FormData();
    //   formData.append('file', _this.file);
    //   formData.append('FileCategory', '1');
    //   _this.http
    //     .post(API.File.UploadFormFile, formData)
    //     .subscribe(
    //       (res) => {
    //         if (res.result.success) {
    //           item.files[item.files.length - 1].fileId = res.result.data.fileId;
    //           console.log(item.files);
    //           _this.backValue();
    //           _this.msg.success(res.result.message);
    //         } else {
    //           _this.msg.error(res.result.message);
    //         }
    //       }
    //     );
    // }
  }
  backValue() {
    const bacaData: any = [];
    this.fileList.forEach(e => {
      if (e.files.length > 0) {
        const id = e.files.map(a => a.response.result.data.fileId);
        bacaData.push({ fileCategory: e.fileClassifyId, fileIds: id, fileIdStr: id.join(',') });
      }
    });
    this.outer.emit(bacaData);
  }
  removeFile = async (file: NzUploadFile) => {
    console.log(file, this.fileList);
    return true;
  }
  handlePreview = async (file: NzUploadFile) => {
    this.previewImage = environment.SERVER_URL + API.File.Attachment + file.response.result.data.fileId; // file.thumbUrl;
    this.viewer = new Viewer(this.img.nativeElement, {
      hide() {
        this.viewer.destroy();
      }
    });
    this.viewer.show();
  }
}
