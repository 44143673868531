import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { SettingsService, _HttpClient } from '@delon/theme';
import { API } from 'src/app/shared/api';
import { EventHubService } from 'src/app/shared/service/chat/event-hub.service';

@Component({
  selector: 'layout-pro-user',
  templateUrl: 'user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutProWidgetUserComponent implements OnInit {
  constructor(
    public settings: SettingsService,
    private router: Router,
    public http: _HttpClient,
    private eventHubSvr: EventHubService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }
  isVisible = false;
  param = {
    currentPassword: '',
    newPassword: '',
  };
  ngOnInit(): void {
    // mock
    const token = this.tokenService.get() || {
      token: 'nothing',
      name: 'Admin',
      avatar: './assets/logo-color.svg',
      email: 'cipchk@qq.com',
    };
    this.tokenService.set(token);
  }
  changePassword() {
    this.isVisible = true;
  }
  handleOk() {
    this.http.post(API.UserChangePassword, this.param).subscribe((res) => {
      this.isVisible = false;
      this.logout();
    });
  }
  logout() {
    this.http.get(API.logout).subscribe();
    this.tokenService.clear();
    localStorage.clear();
    this.router.navigateByUrl(this.tokenService.login_url);
    this.eventHubSvr.destroyAll();
  }
}
