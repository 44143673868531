import { Injectable, NgZone } from '@angular/core';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { environment } from '@env/environment';
import { error } from 'console';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { EventHubService } from './event-hub.service';
import { SignalRService } from './signalR.service';
import { SignalRChatInvoke } from './signalR-chat-invoke';
import { SignalRChatEvent } from './signalR-chat-event';
import { SignalRStatus } from './signalR-status';
/**
 * 聊天 服务
 */
@Injectable({
  providedIn: 'root',
})
export class ChatSignalRService {
  url = `${environment.SERVER_URL.endsWith('/') ? environment.SERVER_URL : environment.SERVER_URL + '/'}signalr-chat`;
  ConnectionStatus: SignalRStatus = SignalRStatus.ReConnecting;
  Hub: HubConnection;
  constructor(
    private signalRSvr: SignalRService,
    public _zone: NgZone,
    public msg: NzMessageService,
    private notificationSvr: NzNotificationService,
    private eventHubSvr: EventHubService,
  ) {}

  /**
   * 初始化
   */
  init(): void {
    this._zone.runOutsideAngular(() => {
      if (this.Hub) {
        this.Hub.stop();
      }

      this.registerEventOn();

      this.eventHubSvr.trigger(SignalRChatEvent.Connecting.Event);

      this.signalRSvr.connect(this.url).subscribe((connection: HubConnection) => {
        this.Hub = connection;
        this.ConnectionStatus = SignalRStatus.Connected;

        // 注册事件
        this.registerConnectionOn(connection);

        this.Hub.onclose(this.close.bind(this));
        this.Hub.onreconnecting(this.onReconnecting.bind(this));
        this.Hub.onreconnected(this.onReconnected.bind(this));

        this.eventHubSvr.trigger(SignalRChatEvent.Connected.Event);

        connection.invoke(SignalRChatInvoke.Register).then(() => {
          console.log('已注册到服务器：SignalR Chat ');
        });
      });
    });
  }

  /**
   * 发送消息
   */
  sendMessage(methodName: string, ...args: any[]): Observable<any> {
    return new Observable((obs) => {
      if (this.Hub?.state !== HubConnectionState.Connected) {
        console.log('signalR未连接：聊天');
        obs.error('聊天signalR未连接');
        return;
      }
      this.Hub.invoke(methodName || SignalRChatInvoke.SendMessage, args)
        .then((result) => {
          obs.next(result);
        })
        .catch((err) => {
          console.log(err);
          obs.error(err);
        });
    });
  }
  /**
   * 已关闭
   */
  private close() {
    this.ConnectionStatus = SignalRStatus.Closed;
    this.eventHubSvr.trigger(SignalRChatEvent.Close.Event);
  }
  /**
   * 已重新连接
   */
  private onReconnected() {
    this.ConnectionStatus = SignalRStatus.Connected;
    this.eventHubSvr.trigger(SignalRChatEvent.Connected.Event);
  }
  /**
   * 重新连接中
   */
  private onReconnecting() {
    this.ConnectionStatus = SignalRStatus.ReConnecting;
    this.eventHubSvr.trigger(SignalRChatEvent.Connecting.Event);
  }

  /**
   * 注册集线器事件（就放到该服务）
   */
  private registerConnectionOn(connection: HubConnection): void {
    console.log('已注册事件：聊天');

    // 聊天信息
    connection.on(SignalRChatEvent.GetChatMessage.Method, (message) => {
      this.eventHubSvr.trigger(SignalRChatEvent.GetChatMessage.Event, message);
    });
    // 好友请求
    connection.on(SignalRChatEvent.GetFriendshipRequest.Method, (friendshipRequest, isOwnRequest) => {
      this.eventHubSvr.trigger(SignalRChatEvent.GetFriendshipRequest.Event, friendshipRequest, isOwnRequest);
    });
    // 用户连接更改
    connection.on(SignalRChatEvent.GetUserConnectNotification.Method, (user, isConnected) => {
      this.eventHubSvr.trigger(SignalRChatEvent.GetUserConnectNotification.Event, user, isConnected);
    });
    // 用户好友请求状态更改
    connection.on(SignalRChatEvent.GetUserStateChange.Method, (user, newState) => {
      this.eventHubSvr.trigger(SignalRChatEvent.GetUserStateChange.Event, user, newState);
    });
    // 用户所有未读消息
    connection.on(SignalRChatEvent.GetAllUnreadMessagesOfUserRead.Method, (user) => {
      this.eventHubSvr.trigger(SignalRChatEvent.GetAllUnreadMessagesOfUserRead.Event, user);
    });
    // 读取状态更改
    connection.on(SignalRChatEvent.GetReadStateChange.Method, (user) => {
      this.eventHubSvr.trigger(SignalRChatEvent.Close.Event, user);
    });
  }
  /**
   * 注册触发器事件（各事件可移动到对应功能处）
   */
  private registerEventOn() {
    this.eventHubSvr.on(SignalRChatEvent.Connected.Event, () => {
      console.log('聊天signalR已连接');
    });
    this.eventHubSvr.on(SignalRChatEvent.Close.Event, () => {
      console.log('聊天signalR已断开');
    });
    this.eventHubSvr.on(SignalRChatEvent.Connecting.Event, () => {
      console.log('聊天signalR连接中');
    });

    // 聊天信息
    this.eventHubSvr.on(SignalRChatEvent.GetChatMessage.Event, (message) => {});
    // 好友请求
    this.eventHubSvr.on(SignalRChatEvent.GetFriendshipRequest.Event, (friendshipRequest, isOwnRequest) => {});
    // 用户连接更改
    this.eventHubSvr.on(SignalRChatEvent.GetUserConnectNotification.Event, (user, isConnected) => {});
    // 用户好友请求状态更改
    this.eventHubSvr.on(SignalRChatEvent.GetUserStateChange.Event, (user, newState) => {});
    // 用户所有未读消息
    this.eventHubSvr.on(SignalRChatEvent.GetAllUnreadMessagesOfUserRead.Event, (user) => {});
    // 读取状态更改
    this.eventHubSvr.on(SignalRChatEvent.GetReadStateChange.Event, (user) => {});
  }
}
