/**
 * SignalR Invoke 聊天执行的后台方法
 * - connection.invoke(SignalRChatInvoke.Register).then(() => {
 * });
 */
export const SignalRChatInvoke = {
  /**
   * 注册
   */
  Register: 'register',
  /**
   * 发送消息
   */
  SendMessage: 'sendMessage',
};
