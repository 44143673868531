import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

/**
 * 枚举名称
 */
export enum EnumName {
  /**
   * 注销类型
   */
  SignOutType,
  /**
   * 收发货类型
   */
  ReceiverTypeEnum,

  /**
   * 车辆生产地枚举
   */
  CarFrom,

  /**
   * 车辆分类枚举
   */
  CarStyle,
  /**
   * 驾驶类型：、单驾双驾
   */
  DriverStyleList,
  /**
   * 车辆运输类型分类枚举
   */
  CarTypeStyle,

  /**
   * 证件分类枚举
   */
  CertificateTypeEnum,
  /**
   * 证件类别枚举
   */
  CertificateCategoryEnum,
  /**
   * 司机分类枚举
   */
  DriverType,

  /**
   * 驾照类型枚举
   */
  DriverLicenseCategoryEnum,

  /**
   * 性别枚举
   */
  SexEnum,

  /**
   * 婚姻状态枚举
   */
  MaritalStatusEnum,

  /**
   * 员工状态枚举
   */
  StatusEnum,
  /**
   * 合同分类
   */
  BillContractStyle,
  /**
   * 合同类型枚举
   *
   */
  ContractStyle,

  /**
   * 合同支付方式枚举
   */
  PaymentStyle,

  /**
   * 支付时间枚举
   */
  PaymentTime,

  /**
   * 托运单状态枚举
   */
  ConsignmentBillState,

  /**
   * 派车单状态枚举
   */
  TransBillState,

  /**
   * 回单类型枚举
   *
   */
  ReceiptStyle,

  /**
   * 运输的货物单位枚举
   */
  UnitStyle,

  /**
   * 货物品种分类单位枚举
   */
  GuigeUnitStyle,

  /**
   * 装货状态枚举
   *
   */
  ZHStatus,

  /**
   * 托运单类型枚举
   */
  BillStyle,

  /**
   * 司机合同分类枚举
   */
  DriverContractStyle,

  /**
   * 文件分类枚举
   */
  FileCategoryEnum,

  /**
   * 报销分类枚举
   */
  CostTypeEnum,

  /**
   * 学历枚举
   *
   */
  RecordFormalSchoolingEnum,

  /**
   * 费用配置枚举
   */
  CostAllocationEnum,

  /**
   * 车辆状态
   */
  CarStatusEnum,

  /**
   * 文件类型枚举
   */
  SafetyEduFileType,
  /**
   * 燃油管理充值类型枚举
   */
  FuelManageEnum,
  /**
   * 结算状态
   */
  SettlementStateEnum,
  /**
   * 短信类型
   */
  SmsTypeEnum,
  /**
   * 货物品种分类单位
   */
  GuiGeUnitStyle,
  /**
   * 油卡持卡状态
   */
  OilCardPeopleEnum,
  /**
   * 处理结果
   */
  HandleResultEnum,

  CancelInsurance,
  Policyholder,
  Assurer,
  CancelResult,
  CancelProfile,
  CancelState,
}

/**
 * 字典枚举名称
 */
export enum DictionaryNameEnum {
  /**
   * 车牌颜色
   */
  CarPlateColor = 1,

  /**
   * 运输类型
   */
  TransportType,

  /**
   * 车型
   */
  CarType,

  /**
   * 客运车型
   */
  PassengerType,

  /**
   * 燃料类型
   */
  FuelType,

  /**
   * 货物分类
   */
  GoodsCategory,

  /**
   * 支付方式
   */
  PayWay,
}

/**
 * 下拉数据类
 */
export class LabelValue<T> {
  label: string;
  value: T;
  parent: string;
  [key: string]: any;
}
