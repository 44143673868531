/**
 * SignalR 连接状态
 */
export enum SignalRStatus {
  /**
   * 连接中
   */
  ReConnecting,
  /**
   * 已连接
   */
  Connected,
  /**
   * 已断开
   */
  Closed,
}
