import { Injectable, NgZone } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { environment } from '@env/environment';
import { error } from 'console';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { EventHubService } from './event-hub.service';
import { SignalRService } from './signalR.service';
import { SignalRNotifyEvent } from './signalR-notify-event';
import { SignalRNotifyInvoke } from './signalR-notify-invoke';
import { SignalRStatus } from './signalR-status';
/**
 * 消息通知 服务
 */
@Injectable({
  providedIn: 'root',
})
export class NotifySignalRService {
  url = `${environment.SERVER_URL.endsWith('/') ? environment.SERVER_URL : environment.SERVER_URL + '/'}signalr`;
  // `https://pay.9t56.cn/signalr`;
  ConnectionStatus: SignalRStatus = SignalRStatus.ReConnecting;
  Hub: HubConnection;

  constructor(
    private signalRSvr: SignalRService,
    public _zone: NgZone,
    public msg: NzMessageService,
    private notificationSvr: NzNotificationService,
    private eventHubSvr: EventHubService,
  ) { }

  /**
   * 初始化
   */
  init(): void {
    this._zone.runOutsideAngular(() => {
      if (this.Hub) {
        this.Hub.stop();
      }

      this.registerEventOn();

      this.eventHubSvr.trigger(SignalRNotifyEvent.Connecting.Event);

      this.signalRSvr.connect(this.url).subscribe((connection: HubConnection) => {
        this.Hub = connection;
        this.ConnectionStatus = SignalRStatus.Connected;

        // 注册事件
        this.registerConnectionOn(connection);
        this.Hub.onclose(this.close.bind(this));
        this.Hub.onreconnecting(this.onReconnecting.bind(this));
        this.Hub.onreconnected(this.onReconnected.bind(this));

        this.eventHubSvr.trigger(SignalRNotifyEvent.Connected.Event);

        connection.invoke(SignalRNotifyInvoke.Register).then(() => {
          console.log('已注册到服务器： SignalR ');
        });
      });
    });
  }

  /**
   * 已关闭
   */
  private close() {
    this.ConnectionStatus = SignalRStatus.Closed;
    this.eventHubSvr.trigger(SignalRNotifyEvent.Close.Event);
  }
  /**
   * 已重新连接
   */
  private onReconnected() {
    this.ConnectionStatus = SignalRStatus.Connected;
    this.eventHubSvr.trigger(SignalRNotifyEvent.Connected.Event);
  }
  /**
   * 重新连接中
   */
  private onReconnecting() {
    this.ConnectionStatus = SignalRStatus.ReConnecting;
    this.eventHubSvr.trigger(SignalRNotifyEvent.Connecting.Event);
  }
  /**
   * 注册集线器事件（就放到该服务）
   */
  private registerConnectionOn(connection: HubConnection): void {
    console.log('已注册事件：消息通知');
    connection.on(SignalRNotifyEvent.Received.Method, (userNotification) => {
      console.log('notify-signalr-', userNotification);
      this.eventHubSvr.trigger(SignalRNotifyEvent.Received.Event, userNotification);
    });
  }
  /**
   * 注册触发器事件（各事件可移动到对应功能处）
   */
  private registerEventOn() {
    // this.eventHubSvr.on(SignalRNotifyEvent.Connected.Event, () => {
    //   console.log('消息通知signalR已连接');
    // });
    // this.eventHubSvr.on(SignalRNotifyEvent.Close.Event, () => {
    //   console.log('消息通知signalR已断开');
    // });
    // this.eventHubSvr.on(SignalRNotifyEvent.Connecting.Event, () => {
    //   console.log('消息通知signalR连接中');
    // });
  }
}
