import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StartupService } from '@core';
import { ReuseTabService } from '@delon/abc/reuse-tab';
import { DA_SERVICE_TOKEN, ITokenService, SocialOpenType, SocialService } from '@delon/auth';
import { SettingsService, _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { API } from 'src/app/shared/api';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'passport-demo-lg-on',
  templateUrl: './demo-lg-on.component.html',
  styleUrls: ['./demo-lg-on.component.less'],
  providers: [SocialService],
})
export class DemoLgOnComponent implements OnInit {
  constructor(
    fb: FormBuilder,
    modalSrv: NzModalService,
    private router: Router,
    private settingsService: SettingsService,
    private socialService: SocialService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: _HttpClient,
    public msg: NzMessageService,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = fb.group({
      companyName: ['Default', [Validators.required]],
      userName: ['admin', [Validators.required]], //  Validators.pattern(/^(admin|user)$/)
      password: ['123456Qwe', [Validators.required]], // Validators.pattern(/^(ng\-alain\.com)$/)
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
      remember: [true],
    });
    modalSrv.closeAll();
  }

  // #region fields
  get companyName() {
    return this.form.controls.companyName;
  }
  get userName() {
    return this.form.controls.userName;
  }
  get password() {
    return this.form.controls.password;
  }
  get mobile() {
    return this.form.controls.mobile;
  }
  get captcha() {
    return this.form.controls.captcha;
  }
  form: FormGroup;
  error = '';
  type = 0;

  // #region get captcha

  count = 0;
  interval$: any;

  // #endregion

  switch(ret: any) {
    this.type = ret.index;
  }

  getCaptcha() {
    if (this.mobile.invalid) {
      this.mobile.markAsDirty({ onlySelf: true });
      this.mobile.updateValueAndValidity({ onlySelf: true });
      return;
    }
    this.count = 59;
    this.interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) {
        clearInterval(this.interval$);
      }
    }, 1000);
  }

  // #endregion

  submit() {
    this.error = '';
    if (this.type === 0) {
      this.userName.markAsDirty();
      this.userName.updateValueAndValidity();
      this.password.markAsDirty();
      this.password.updateValueAndValidity();
      this.companyName.markAsDirty();
      this.companyName.updateValueAndValidity();
      if (this.userName.invalid || this.password.invalid || this.companyName.invalid) {
        return;
      }
    } else {
      this.mobile.markAsDirty();
      this.mobile.updateValueAndValidity();
      this.captcha.markAsDirty();
      this.captcha.updateValueAndValidity();
      if (this.mobile.invalid || this.captcha.invalid) {
        return;
      }
    }

    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    this.http
      .post(`${API.IsTenantAvailable}?_allow_anonymous=true`, {
        tenancyName: this.companyName.value,
      })
      .subscribe((res: any) => {
        if (res.success !== true || !res.result.tenantId) {
          this.error = '公司名称不存在';
          return;
        }
        this.getTokenAuth(res.result.tenantId);
      });
  }
  getTokenAuth(tenantId) {
    this.http
      .post(
        `${API.Login}?_allow_anonymous=true`,
        {
          userNameOrEmailAddress: this.userName.value,
          password: this.password.value,
          rememberClient: true,
        },
        null,
        {
          headers: {
            ['Abp.TenantId']: tenantId + '',
          },
        },
      )
      .subscribe((res: any) => {
        console.log(res);
        if (res.success !== true) {
          this.error = '用户名或密码错误';
          return;
        }
        const user = {
          token: res.result.accessToken,
          name: this.userName.value,
          email: res.result.encryptedAccessToken,
          id: res.result.userId,
          time: res.result.expireInSeconds,
          tenantId,
        };
        const tokenExpireDate = new Date(new Date().getTime() + 1000 * res.result.expireInSeconds);
        this.commonService.deleteCookie('Abp.AuthToken', '/');
        this.commonService.setCookieValue('Abp.AuthToken', res.result.accessToken, tokenExpireDate, '/', '.wsmsjm.cn');
        // 清空路由复用信息
        this.reuseTabService.clear();
        // 设置用户Token信息
        this.tokenService.set(user);
        // tslint:disable-next-line:no-unused-expression

        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load().then(() => {
          let url = this.tokenService.referrer.url || '/';
          console.log(url);
          if (url.includes('/passport')) {
            url = '/';
          }
          this.router.navigateByUrl('/dashboard');
        });
        if (!localStorage.getItem('areaNode')) {
          const list = [];
          this.commonService.getData().then((data) => {
            data.result.items.forEach((element) => {
              list.push({ label: element.shortName, value: element.id, parent: element.parentId ? element.parentId : '0' });
            });
            localStorage.setItem('areaNode', JSON.stringify(list));
          });
        }
      });
  }

  // #region social

  open(type: string, openType: SocialOpenType = 'href') {
    let url = ``;
    let callback = ``;
    // tslint:disable-next-line: prefer-conditional-expression
    if (environment.production) {
      callback = 'https://ng-alain.github.io/ng-alain/#/callback/' + type;
    } else {
      callback = 'http://localhost:4200/#/callback/' + type;
    }
    switch (type) {
      case 'auth0':
        url = `//cipchk.auth0.com/login?client=8gcNydIDzGBYxzqV0Vm1CX_RXH-wsWo5&redirect_uri=${decodeURIComponent(callback)}`;
        break;
      case 'github':
        url = `//github.com/login/oauth/authorize?client_id=9d6baae4b04a23fcafa2&response_type=code&redirect_uri=${decodeURIComponent(
          callback,
        )}`;
        break;
      case 'weibo':
        url = `https://api.weibo.com/oauth2/authorize?client_id=1239507802&response_type=code&redirect_uri=${decodeURIComponent(callback)}`;
        break;
    }
    if (openType === 'window') {
      this.socialService
        .login(url, '/', {
          type: 'window',
        })
        .subscribe((res) => {
          if (res) {
            this.settingsService.setUser(res);
            this.router.navigateByUrl('/');
          }
        });
    } else {
      this.socialService.login(url, '/', {
        type: 'href',
      });
    }
  }

  // #endregion

  // ngOnDestroy(): void {
  //   if (this.interval$) {
  //     clearInterval(this.interval$);
  //   }
  // }
  ngOnInit() {


    // if (!this.code || this.code.length < 30) {
    //   this.activatedRoute.queryParams.subscribe(queryParams => {
    //     console.log(queryParams.code);
    //     this.code = queryParams.code; // 获取传过来的发票id
    //   });
    // }
    this.activatedRoute.params.subscribe((params: any) => {
      const cd = params.message;
      // console.log(cd);
      // 9f56
      // fhggjghoihih
      const paramArr = cd.split('&');
      this.form.controls.userName.setValue(paramArr[1]);
      this.form.controls.password.setValue(paramArr[2]);
      // this.validateCode = '9f56';
      // this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJBc3BOZXQuSWRlbnRpdHkuU2VjdXJpdHlTdGFtcCI6IjBiZTdkMGVhLTEwMWQtNjE4Ni1hZDMzLTM5ZjkxMGI0N2VmNiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwic3ViIjoiMSIsImp0aSI6IjZkZjQ5Y2Q0LTUwZTQtNGQ0ZS1hZGZiLTNmZDVlMTEwOTExOSIsImlhdCI6MTYwNjkwMzQ2NiwibmJmIjoxNjA2OTAzNDY2LCJleHAiOjE2MDY5ODk4NjYsImlzcyI6Ikludm9pY2UiLCJhdWQiOiJJbnZvaWNlIn0.m1I-Ox42SvZk2B60OMeVTgTgIj-2to0jcfJj1Y-EDHE';
      this.submit();
    });
    // this.logoChange(this.code);
    // this.ChangeImg();
  }
}

