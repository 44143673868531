/**
 * SignalR 消息通知事件
 * - connection.on(SignalRNotifyEvent.Received.Method, (msg) => {
 * eventHubService.trigger(SignalRNotifyEvent.Received.Event,msg);
 * });
 * - eventHubService.on(SignalRNotifyEvent.Received.Event,(msg)=>{});
 */
export const SignalRNotifyEvent = {
  /**
   * 连接中
   */
  Connecting: {
    Method: '',
    Event: 'app.notify.Connecting',
  },
  /**
   * 接收消息通知
   */
  Received: {
    Method: 'getNotification', // abp消息通知默认名称
    Event: 'app.notify.received',
  },
  /**
   * 已连接
   */
  Connected: {
    Method: '',
    Event: 'app.notify.Connected',
  },
  /**
   * 已断开
   */
  Close: {
    Method: '',
    Event: 'app.notify.Close',
  },
};
