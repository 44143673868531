import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// layout
// import { LayoutDefaultComponent } from '../layout/default/default.component';
// import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { LayoutProComponent } from '@brand';
import { SimpleGuard } from '@delon/auth';
import { environment } from '@env/environment';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
// dashboard pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoLgOnComponent } from './passport/demo-lg-on/demo-lg-on.component';
import { UserLockComponent } from './passport/lock/lock.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
import { UserRegisterComponent } from './passport/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutProComponent,
    canActivate: [SimpleGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: { title: '首页' } },
      {
        path: 'exception',
        loadChildren: () => import('./exception/exception.module').then((m) => m.ExceptionModule)
      },
      // 例子
      {
        path: 'my-demo',
        loadChildren: () => import('./my-demo/my-demo.module').then((m) => m.MyDemoModule),
        data: { title: '例子管理' }
      },
      {
        path: 'financial', // title: '财务管理'
        loadChildren: () => import('./financial/financial.module').then((m) => m.FinancialModule),
        data: {}
      },
      {
        path: 'my-edu', //  title: '安全教育培训'
        loadChildren: () => import('./my-edu/my-edu.module').then((m) => m.MyEduModule),
        data: {}
      },
      {
        path: 'my-consignment', //  title: '运单管理'
        loadChildren: () => import('./my-consignment/my-consignment.module').then((m) => m.MyConsignmentModule),
        data: {}
      },
      {
        path: 'my-contract', // title: '合同管理'
        loadChildren: () => import('./my-contract/my-contract.module').then((m) => m.MyContractModule),
        data: {}
      },
      {
        path: 'car', //  title: '车辆管理'
        loadChildren: () => import('./car/car.module').then((m) => m.CarModule),
        data: {}
      },
      {
        path: 'my-sys', // title: '系统设置'
        loadChildren: () => import('./my-sys/my-sys.module').then((m) => m.MySysModule),
        data: {}
      },
      {
        path: 'my-enterprise', // title: '公司管理'
        loadChildren: () => import('./my-enterprise/my-enterprise.module').then((m) => m.MyEnterpriseModule),
        data: {}
      },
      {
        path: 'data-service', // title: '信用服务'
        loadChildren: () => import('./data-service/data-service.module').then((m) => m.DataServiceModule),
        data: {}
      },
      {
        path: 'my-office',
        loadChildren: () => import('./my-office/my-office.module').then((m) => m.MyOfficeModule),
        data: {}
      }
    ]
  },
  // 全屏布局
  // {
  //     path: 'fullscreen',
  //     component: LayoutFullScreenComponent,
  //     children: [
  //     ]
  // },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      { path: 'login', component: UserLoginComponent, data: { title: '登录', titleI18n: 'pro-login' } },
      {
        path: 'demoLgOn/:message',
        component: DemoLgOnComponent,
        data: { title: '登录', titleI18n: 'pro-login' },
      },
      { path: 'register', component: UserRegisterComponent, data: { title: '注册', titleI18n: 'pro-register' } },
      {
        path: 'register-result',
        component: UserRegisterResultComponent,
        data: { title: '注册结果', titleI18n: 'pro-register-result' }
      },
      { path: 'lock', component: UserLockComponent, data: { title: '锁屏', titleI18n: 'lock' } }
    ]
  },
  // 单页不包裹Layout
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule { }
