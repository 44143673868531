import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  constructor(private http: _HttpClient) { }
  height = 300;
  ngOnInit() {
    document.getElementById('test').style.height = (document.documentElement.clientHeight - 115) + '';
    this.height = (document.documentElement.clientHeight - 115);
  }
}
