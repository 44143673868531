// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   SERVER_URL: `https://manage.wsmsjm.cn`,
  //SERVER_URL: `http://localhost:7785/`, // net http://192.168.1.114:9086/  //`http://36.138.249.42:8014/`,
  // SERVER_URL: `http://localhost:7785/`, // net
  // SERVER_URL: `https://manage.wsmsjm.cn`, // net
  DataService_Url: 'http://sys.9f56.com/api',
  production: false,
  useHash: true,
  hmr: false,
  pro: {
    theme: 'green',
    menu: 'top',
    contentWidth: 'fixed',
    fixedHeader: false,
    autoHideHeader: false,
    fixSiderbar: false,
    onlyIcon: true,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
