import { Inject, Injectable, Injector, NgZone } from '@angular/core';
/**
 * 事件服务
 */
@Injectable({
  providedIn: 'root',
})
export class EventHubService {
  constructor() {}

  private _callbacks: { [eventName: string]: ((...args: any[]) => any)[] } = {};

  /**
   * 触发
   */
  trigger(eventName, ...args: any[]) {
    const callbacks = this._callbacks[eventName];
    
    if (!callbacks || !callbacks.length) {
      return;
    }
    for (let i = 0; i < callbacks.length; i++) {
      callbacks[i].apply(this, args);
    }
  }

  /**
   * 监听
   */
  on(eventName, callback: (...args: any[]) => any) {
    if (!this._callbacks[eventName]) {
      this._callbacks[eventName] = [];
    }

    this._callbacks[eventName].push(callback);
  }
  /**
   * 关闭
   */
  off(eventName, callback: (...args: any[]) => any) {
    const callbacks = this._callbacks[eventName];
    if (!callbacks) {
      return;
    }

    let index = -1;
    for (let i = 0; i < callbacks.length; i++) {
      if (callbacks[i] === callback) {
        index = i;
        break;
      }
    }

    if (index < 0) {
      return;
    }

    this._callbacks[eventName].splice(index, 1);
  }
  /**
   * 摧毁所有
   */
  destroyAll() {
    this._callbacks = {};
  }
}
