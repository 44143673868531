/**
 * SignalR 聊天 事件
 * - connection.on(SignalRChatEvent.GetChatMessage.Method, (msg) => {
 * eventHubService.trigger(SignalRChatEvent.GetChatMessage.Event,msg);
 * });
 * - eventHubService.on(SignalRChatEvent.GetChatMessage.Event,(msg)=>{});
 */
export const SignalRChatEvent = {
  /**
   * 连接中
   */
  Connecting: {
    Method: '',
    Event: 'app.chat.Connecting',
  },
  /**
   * 已连接
   */
  Connected: {
    Method: '',
    Event: 'app.chat.Connected',
  },
  /**
   * 已断开
   */
  Close: {
    Method: '',
    Event: 'app.chat.Close',
  },
  /**
   * 聊天信息
   */
  GetChatMessage: {
    Method: 'getChatMessage',
    Event: 'app.chat.getChatMessage',
  },
  /**
   * 好友请求
   */
  GetFriendshipRequest: {
    Method: 'getFriendshipRequest',
    Event: 'app.chat.getFriendshipRequest',
  },
  /**
   * 用户连接更改
   */
  GetUserConnectNotification: {
    Method: 'getUserConnectNotification',
    Event: 'app.chat.getUserConnectNotification',
  },
  /**
   * 用户好友请求状态更改
   */
  GetUserStateChange: {
    Method: 'getUserStateChange',
    Event: 'app.chat.getUserStateChange',
  },
  /**
   * 用户所有未读消息
   */
  GetAllUnreadMessagesOfUserRead: {
    Method: 'getAllUnreadMessagesOfUserRead',
    Event: 'app.chat.getAllUnreadMessagesOfUserRead',
  },
  /**
   * 读取状态更改
   */
  GetReadStateChange: {
    Method: 'getReadStateChange',
    Event: 'app.chat.getReadStateChange',
  },
};
