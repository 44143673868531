import { DatePipe } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import { Lodop } from '@delon/abc/lodop';
import { SFSchema } from '@delon/form';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { API } from '../api';
import { EnumName, LabelValue } from '../class/common';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line:component-class-suffix
export class CommonService {
  /**
   * 公共服务
   */
  constructor(private http: _HttpClient, private datePipe: DatePipe,) { }

  /**
   * 获取枚举下拉
   * @param enumName 枚举名称
   */
  getEnumDropDown(enumName: string | EnumName): Observable<LabelValue<string>[]> {
    if (typeof enumName !== 'string') {
      enumName = EnumName[enumName];
    }
    return this.getDropDown(API.GetDropDownList, { enumName });
  }
  /**
   * 获取下拉数据
   * @param url 请求地址
   * @param params 参数
   */
  getDropDown(url: string, params?: any, style?: any): Observable<LabelValue<string>[]> {
    return this.http.get(url, params).pipe(
      mergeMap((res: any) => {
        const list = [];
        const data = res.result.items ?? res.result;
        if (!style) {
          data.forEach((val: { value: any; name: any;[key: string]: any }) => {
            list.push({ value: val.value, label: val.name, ...val });
          });
        } else {
          data.forEach((val: { id: any; name: any;[key: string]: any }) => {
            list.push({ value: val.name, label: val.name, ...val });
          });
        }
        return of(list);
      }),
    );
  }

  /**
   * 文件下载
   * @param fileId 文件ID
   * @param saveName 保存的文件名（含后缀），可为空
   * @param downloadFileName 下载的文件名，可为空（为空默认）
   */
  downloadFile(fileId: string, fileUrl?: string, saveName?: string, downloadFileName?: string) {
    this.http.get(environment.SERVER_URL + (fileUrl ? fileUrl : API.File.DownFile) + fileId + (saveName ? ('/' + saveName) : ''), null, { responseType: 'blob' }).subscribe((data) => {
      // 下载类型 xls
      // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      // 下载类型：csv
      // const contentType2 = 'text/csv';
      // const blob = new Blob([data], { type: contentType });
      const url = window.URL.createObjectURL(data);
      console.log('downloadFile', url);
      // 打开新窗口方式进行下载
      // window.open(url);

      // 以动态创建a标签进行下载
      const a = document.createElement('a');
      downloadFileName = downloadFileName ?? this.datePipe.transform(new Date(), 'yyyyMMddHHmmss');
      a.href = url;
      a.download = downloadFileName;
      // a.download = fileName + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }
  async previewFile(fileId: string, saveName?: string, downloadFileName?: string) {
    const data = await this.http.get(environment.SERVER_URL + API.File.DownThumbnailFile + fileId + (saveName ? ('/' + saveName) : ''), null, { responseType: 'blob' }).toPromise();
    // this.blobToBase64(data).then((res: any) => {
    //   // 转化后的base64
    //   console.log('previewFile', res);
    //   return res.result;
    // });
    return data;
    // return this.http.get(API.File.DownThumbnailFile + fileId + (saveName ? ('/' + saveName) : ''), null, { responseType: 'blob' }).pipe(
    //   mergeMap((res: any) => {
    //     let list = '';
    //     this.blobToBase64(res).then((data: any) => {
    //       // 转化后的base64
    //       console.log('previewFile', data);
    //       list = data.result;
    //     });
    //     return list;
    //   }),
    //   // mergeMap((data: any) => {
    //   //   console.log('DownThumbnailFile', data);
    //   //   this.blobToBase64(data).then((res: any) => {
    //   //     // 转化后的base64
    //   //     console.log('previewFile', res);
    //   //     return of(res.result);
    //   //   });
    //   // }),
    // );
  }
  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        resolve(e.target);
      };
      // readAsDataURL
      fileReader.readAsDataURL(blob);
      fileReader.onerror = () => {
        reject(new Error('文件流异常'));
      };
    });
  }
  async getData() {
    const data = await this.http.get(API.GetArea).toPromise();
    return data;
  }

  // tslint:disable-next-line:only-arrow-functions
  setCookieValue = function (key, value, expireDate, path, domain) {
    let cookieValue = encodeURIComponent(key) + '=';

    if (value) {
      cookieValue = cookieValue + encodeURIComponent(value);
    }

    if (expireDate) {
      cookieValue = cookieValue + '; expires=' + expireDate.toUTCString();
    }

    if (path) {
      cookieValue = cookieValue + '; path=' + path;
    }

    if (domain) {
      cookieValue = cookieValue + '; domain=' + domain;
    }

    document.cookie = cookieValue;
  };
  // tslint:disable-next-line:only-arrow-functions
  deleteCookie = function (key, path) {
    let cookieValue = encodeURIComponent(key) + '=';

    cookieValue = cookieValue + '; expires=' + (new Date(new Date().getTime() - 86400000)).toUTCString();

    if (path) {
      cookieValue = cookieValue + '; path=' + path;
    }
    document.cookie = cookieValue;
  };
  // // 用 ng-alain 自带管道实现
  // smalltoBIG(n): string {
  //   const fraction = ['角', '分'];
  //   const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  //   const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  //   const head = n < 0 ? '欠' : '';
  //   n = Math.abs(n);

  //   let s = '';

  //   for (let i = 0; i < fraction.length; i++) {
  //     s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  //   }
  //   s = s || '整';
  //   n = Math.floor(n);

  //   for (let i = 0; i < unit[0].length && n > 0; i++) {
  //     let p = '';
  //     for (let j = 0; j < unit[1].length && n > 0; j++) {
  //       p = digit[n % 10] + unit[1][j] + p;
  //       n = Math.floor(n / 10);
  //     }
  //     s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  //   }
  //   return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
  // }
  print(isPrivew: boolean = false, html: any, lodop: Lodop, isA4: boolean = true): void {

    const cp = localStorage.getItem('teantName');
    // tslint:disable-next-line:no-non-null-assertion
    const LODOP = lodop!;
    LODOP.PRINT_INIT(cp);
    // LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
    // if (isA4) {
    //   // LODOP.SET_PRINT_PAGESIZE(1, 2970, 2100, '');
    //   // LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4');
    //   LODOP.SET_PRINT_STYLE('Bold', 0);
    // } else {
    //   LODOP.SET_PRINT_STYLEA(0, 'ItemType', 4);
    // }
    // LODOP.ADD_PRINT_TEXT(1, 1, 300, 200, '下面输出的是本页源代码及其展现效果：');
    // LODOP.ADD_PRINT_TEXT(20, 10, '90%', '95%', this.cog.html);
    // LODOP.NEWPAGE();
    LODOP.ADD_PRINT_HTM('0%', '0%', '100%', '100%', html); // 5 5
    if (isPrivew) { LODOP.PREVIEW(); }
    else { LODOP.PRINT(); }
  }
}
